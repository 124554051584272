import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Legend, Title, Tooltip } from 'chart.js'

ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, Legend, Title, Tooltip
)


export default function SalesGraph({ orders }) {

  const thisMonth = new Date().getFullYear() + "-" + (new Date().getMonth()+1)
  const [filteredOrders, setFilteredOrders] = useState([])
  const [month, setMonth] = useState(thisMonth.toString())


  useEffect(() => {
    if (typeof orders === 'object') {
      let fOrders = orders.filter(order => {
        if (order.status === "delivered" && order.date.includes(month)) { return order }
      })
      fOrders = fOrders.reverse().map(order => {
        return { date: order.date.split('T')[0], totalPrice: order.totalPrice, status: order.status }
      })
      let finalArr = []
      let found = false;
      let index = -1;
      for (let i = 0; i < fOrders.length; i++) {
        found = false;
        for (let j = 0; j < finalArr.length; j++) {
          if (fOrders[i].date === finalArr[j].date) {
            found = true;
            break;
          }
        }
        if (!found) {
          finalArr.push(fOrders[i])
          index++;
        }
        else {
          // console.log("FINAL ARR INDEX", index, "TOTAL PRICE", finalArr[index].totalPrice)
          finalArr[index].totalPrice += fOrders[i].totalPrice
        }
      }
      console.log("FINAL ARRAY", finalArr)
      setFilteredOrders(finalArr)
    }
  }, [month])

  const options = {tension: 0.4}
  const lineChartData = {
    labels: filteredOrders.map(each => { return each.date.split("-").reverse().join("-"); }),
    datasets: [
      {
        label: 'Sales',
        data: filteredOrders.map(each => { return each.totalPrice }),
        backgroundColor: ['green', 'blue'],
        borderColor: '#858585'
      }
    ]
  }


  return (
    <div className='w-full'>
      <div className='p-2 flex justify-between items-center'>
        <div className='py-2 px-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
        <p>Sales of dd-{month.split('-').reverse().join("-")}</p>
        </div>
        <input onChange={(e) => {setMonth(e.target.value)}} value={month} type='month' className='py-2 px-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg' />
      </div>
      <Line options={options} data={lineChartData} />
    </div>
  )
}
