import React, { useReducer, useState } from 'react'
import MainTemplate from './MainTemplate'

const initState = {
    value: 1,
    message: null
}

const reducer = (state, action) => {
    if (action === "increment") {
        if (state.value < 10) return { ...state, message: null, value: state.value + 1 }
        else return { ...state, message: "Maximum reached" }
    }
    else if (action === "decrement") {
        if (state.value > 1) return { ...state, message: null, value: state.value - 1 }
        else return { ...state, message: "Minimum reached" }
    }
    else {
        return state;
    }
}

const initFav = []
const favReducer = (state, action) => {
    if(action.type === "add"){
        const filteredData = state?.filter(data => {if(data?.name !== action.data.name) return data})
        return [...filteredData, action.data]
    }
}

export default function Favorites() {
    const [state, dispatch] = useReducer(reducer, initState);
    const [favs, favDispach] = useReducer(favReducer, initFav);
    const [name, setName] = useState("")

    function handleAdd(e){
        e.preventDefault();
        if(!name) return;
        const main = {type: 'add', data: {name, value: state.value}};
        favDispach(main);

    }

    return (
        <MainTemplate>
            <div className='p-2'>
                <div className='flex flex-col'>
                    <div className='w-full mb-1'>
                        <p>Name</p>
                        <input className='w-full p-2 border border-slate-300 outline-none focus:border-purple-600' placeholder='Enter favorites' onChange={e => setName(e.target.value)} value={name} />
                    </div>
                    <div className='w-full mb-1'>
                        <p>Amount</p>
                        <div className='flex'>
                            <button onClick={() => { dispatch("decrement") }} className='p-3 border border-slate-300'>-</button>
                            <p className='p-3 border border-slate-300'>{state.value}</p>
                            <button onClick={() => { dispatch("increment") }} className='p-3 border border-slate-300'>+</button>
                        </div>
                        <p className='text-sm'>{state?.message || ''}</p>
                    </div>
                    <div className='w-full my-2'>
                        <button onClick={e => handleAdd(e)} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Add</button>
                    </div>

                    {favs && (
                        <div className='flex flex-col gap-2'>
                            {favs.map((fav, index) => (((
                                <div className='bg-white p-3 rounded-md shadow-md shadow-slate-300 text-slate-800' key={"fav-"+index+1}>
                                    <p>{fav.name}</p>
                                    <p>{fav.value}</p>
                                </div>
                            ))))}
                        </div>
                    )}
                </div>
            </div>
        </MainTemplate>
    )
}
