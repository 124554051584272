import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { addProductMoreDetailsAPI, deleteProductMoreDetailsAPI, getProductMoreDetailsAPI, updateProductMoreDetailsAPI } from '../../../lib/api/productsAPI'

export default function MoreDetailsSection({ extraDetails, setExtraDetails, productId }) {

  const [extraDetailField, setExtraDetailField] = useState({ key: "", value: "" })
  const [allowUpdateED, setAllowUpdateED] = useState({ index: null, update: false, detailsId: "" })

  useEffect(() => {
    productId && getProductMoreDetailsById()
  }, [])

  async function getProductMoreDetailsById(){
    if(productId){
      const response = await getProductMoreDetailsAPI(productId)
      console.log("RESPONSE FROM SERVER", response)
      if(response.status === 200){
        setExtraDetails(response.data);
      }
    }
  }

  

  async function addAnExtraDetail() {
    if (!extraDetailField.key || !extraDetailField.value) {
      return;
    }
    if (extraDetails && extraDetails !== null) {
      setExtraDetails([...extraDetails, extraDetailField]);
      await addMoreDetailsToServer()
    }
    else {
      setExtraDetails([extraDetailField]);
    }
    setExtraDetailField({ key: "", value: "" })
  }

  async function handleExtraDetailEdit(index, type, eachDetail) {
    if (type === "delete") {
      let arr = extraDetails.filter((each, i) => { if (i !== index) return each })
      await deleteMoreDetailsToServer(eachDetail.detailsId)
      setExtraDetails(arr)
    }
    else if (type === "update") {
      setExtraDetailField(eachDetail)
      setAllowUpdateED({ index: index, update: true, detailsId: eachDetail.detailsId  });
    }
  }

  async function handleUpdate() {
    let arr = extraDetails.map((each, i) => {
      if (i === allowUpdateED.index) return extraDetailField
      return each;
    })
    await updateMoreDetailsToServer()
    setExtraDetails(arr)
    setExtraDetailField({ key: "", value: "" })
    setAllowUpdateED({ index: null, update: false })
  }

  async function addMoreDetailsToServer(){
    if(productId){
      const data = {productId, ...extraDetailField}
      const response = await addProductMoreDetailsAPI(data)
      if(response.status === 200){
        // getProductMoreDetailsById()
        setExtraDetails([...extraDetails, response.data])
      }
    }
  }

  async function updateMoreDetailsToServer(){
    if(productId){
      const data = {detailsId: allowUpdateED.detailsId, productId, ...extraDetailField}
      const response = await updateProductMoreDetailsAPI(data)
      if(response.status === 200){
        // getProductMoreDetailsById()
        const filter = extraDetails.filter((eachDetail) => {if(eachDetail.detailsId !== response.data.detailsId) return eachDetail})
        setExtraDetails([...filter, response.data])
      }
    }
  }

  async function deleteMoreDetailsToServer(detailsId){
    if(productId && detailsId){
      const data = {detailsId, productId}
      const response = await deleteProductMoreDetailsAPI(data)
      if(response.status === 200){
        // getProductMoreDetailsById()
        const filter = extraDetails.filter((eachDetail) => {if(eachDetail.detailsId !== response.data.detailsId) return eachDetail})
        setExtraDetails([...filter, response.data])
      }
    }
  }

  return (
    <div className='p-3 bg-white border border-slate-300 shadow-md shadow-slate-400 rounded-lg'>
      <div className="mb-3 flex gap-1">
        {/* <input type='checkbox' /> */}
        <p className="text-slate-800">Add More Details to Product <span className='text-purple-600 text-xs'>(optional)</span></p>
      </div>
      <div className='my-2 p-2 bg-white rounded-md text-slate-600'>
        <p>Add a KEY - VALUE pair for more details section for example.</p>
        <p>Size's Available: 25, 26, 27, 28, 29</p>
      </div>
      <div className="mb-3 flex flex-wrap items-end gap-2">
        <div>
          <p className="text-slate-800">Key</p>
          <input onChange={e => setExtraDetailField({ ...extraDetailField, key: e.target.value })} value={extraDetailField.key} className='border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500' />
        </div>
        <div>
          <p className="text-slate-800">Value</p>
          <input onChange={e => setExtraDetailField({ ...extraDetailField, value: e.target.value })} value={extraDetailField.value} className='border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500' />
        </div>
        <div className='flex gap-2 flex-wrap'>
          {allowUpdateED.update ? (
            <button onClick={() => { handleUpdate() }} className="py-2.5 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Update</button>
          ) : (
            <button onClick={() => { addAnExtraDetail() }} className="py-2.5 px-8 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Add</button>
          )}

        </div>
      </div>
      {extraDetails !== null && extraDetails.length > 0 && (
        <div className='table-generator overflow-auto'>
          <table class="table-fixed border-collapse border-2 border-slate-300 bg-white">
            <thead>
              <tr>
                <th className='border-2 border-slate-300 p-2'>Key</th>
                <th className='border-2 border-slate-300 p-2'>Value</th>
                <th className='border-2 border-slate-300 p-2'>Edit</th>
                <th className='border-2 border-slate-300 p-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {extraDetails.map((eachDetail, index) => (((
                <tr key={`extra-detail-${index}`}>
                  <td className='border-2 border-slate-300 p-2'>{eachDetail.key}</td>
                  <td className='border-2 border-slate-300 p-2'>{eachDetail.value}</td>
                  <td className='border-2 border-slate-300 py-2 px-4'><button onClick={() => { handleExtraDetailEdit(index, "update", eachDetail) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faPen} /> </button></td>
                  <td className='border-2 border-slate-300 py-2 px-4'><button onClick={() => { handleExtraDetailEdit(index, "delete", eachDetail) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faTrash} /></button></td>
                </tr>
              ))))}

            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
