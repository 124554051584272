import React, { useState } from 'react'
import MainTemplate from '../MainTemplate'
import Login from './Login'
import Register from './Register'
import Container from '../Container'

export default function UserAuthPage() {

    const [view, setView] = useState("Login")

    return (
        <MainTemplate>
            <Container>
                <div className='w-full md:w-11/12 lg:w-7/12 mx-auto'>
                    <div className='mb-3 text-center text-lg'>
                        <p>You're not Signed in</p>
                    </div>
                    <div className='inline-flex overflow-hidden rounded-sm'>
                        <button onClick={(e) => { setView("Login") }} className={`py-2.5 px-4 border-b-4 ${view === 'Login' ? 'border-slate-700' : 'border-slate-300 text-slate-400'} px-4 duration-300`}>Log In</button>
                        <button onClick={(e) => { setView("Register") }} className={`py-2.5 px-4 border-b-4 ${view === 'Register' ? 'border-slate-700' : 'border-slate-300 text-slate-400'} px-4 duration-300`}>Register</button>
                    </div>
                    <div className='mt-4'>
                        {view === "Login" ? <Login /> : <Register />}
                    </div>
                </div>
            </Container>
        </MainTemplate>
    )
}
