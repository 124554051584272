import React, { useEffect, useState } from 'react'
import { addCategoryAPI, deleteCategoryAPI, getCategoriesAPI, updateCategoryAPI } from '../../../lib/api/categoryAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

export default function CategoryComponent() {

    const [collapsed, setCollapsed] = useState(true)
    const [categoryInput, setCategoryInput] = useState("")
    const [categories, setCategories] = useState({ status: 0, data: [] })
    const [isUpdate, setIsUpdate] = useState({ status: false, id: null })

    useEffect(() => {
        getCategories()
    }, [])

    async function getCategories() {
        const response = await getCategoriesAPI()
        console.log("CATEGORY RES", response)
        if(response.status === 200){
            setCategories(response)
        }
    }

    async function addCategory() {
        if (!categoryInput) return
        const fcategories = categories?.data?.filter((category) => { if (categoryInput.toLowerCase() === category.name.toLowerCase()) { return category } })
        if (fcategories[0]) return

        const response = await addCategoryAPI({ name: categoryInput })
        if (response.status === 200) {
            console.log("CATE RESPONSE", response)
            getCategories()
            setCategoryInput("")
        }
    }

    async function deleteCategory(id) {
        const response = await deleteCategoryAPI({ categoryId: id })
        if (response.status === 200) {
            getCategories()
        }
    }

    async function updateCategory() {
        const response = await updateCategoryAPI({ categoryId: isUpdate.id, name: categoryInput })
        if (response.status === 200) {
            getCategories()
            setIsUpdate({ status: false, id: null })
            setCategoryInput("")
        }
    }

    return (
        <div className='relative'>
            <div className='absolute w-full flex justify-center' style={{ bottom: -22 }}>
                <button onClick={() => { setCollapsed(!collapsed) }} className='bg-white py-2.5 px-6 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                    <FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp} />
                </button>
            </div>
            <p className='md:text-base'>Add Category</p>
            <div className='bg-white my-2 p-2 border border-slate-300 rounded-lg text-slate-600'>
                    <p>Note: Cannot delete any categories that already have an assigned product, you can only update them.</p>
                </div>
            <div className='flex gap-2'>
                <input onChange={(e) => setCategoryInput(e.target.value)} value={categoryInput} className='w-full p-2 border-2 border-slate-300 rounded-sm outline-none focus:border-purple-600' />
                <button onClick={() => !isUpdate.status ? addCategory() : updateCategory()} className='py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>{!isUpdate.status ? 'Add' : 'Update'}</button>
            </div>
            <div className={`overflow-hidden ${collapsed ? 'h-[10vh]' : 'h-full'}`}>
                {categories?.status === 200 && categories?.data?.length > 0 && (
                    <div className='bg-white mt-2 p-2 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                        {categories.data.map((category, index) => ((
                            <div key={category.categoryId} className={`p-1 flex justify-between items-center ${categories.data.length - 1 !== index && 'border-b borde-slate-300'}`}>
                                <p>
                                    {category.name}
                                    {category?.products?.length !== 0 && <span className='text-purple-600 font-semibold'> - {category?.products?.length}</span>}
                                </p>
                                <div className='flex gap-2'>
                                    <button onClick={() => { setIsUpdate({ status: true, id: category.categoryId }); setCategoryInput(category.name) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faPen} /> </button>
                                    <button onClick={() => { deleteCategory(category.categoryId) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md disabled:bg-slate-300 disabled:text-slate-400' disabled={category?.products?.length > 0}> <FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                            </div>
                        )))}
                    </div>
                )}
            </div>
        </div>
    )
}
