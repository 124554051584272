import React from 'react'
import { ToastContainer } from 'react-toastify';

export default function CustomToast({ children }) {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      closeOnClick
      pauseOnHover
    >
      {children}
    </ToastContainer>
  )
}
