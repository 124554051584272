import { createSlice } from '@reduxjs/toolkit'

const initCart = JSON.parse(sessionStorage.getItem("cart")) || []

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initCart,
    reducers: {
        addToCart: (state, action) => {
            let newCart;
            if (state?.length === 0) {
                let filteredCart = state.filter(item => { if (item.productId !== action.payload.data.productId) return item })
                newCart = [...filteredCart, action.payload.data]
            }
            else {
                newCart = [action.payload.data]
            }
            sessionStorage.setItem("cart", JSON.stringify(newCart))
            state = newCart
        },
        plus: (state, action) => {
            let newCart;
            newCart = state.filter(item => {
                if (item.productId === action.payload.productId && item.quantity < item.stock) {
                    item.quantity = item.quantity + 1
                }
                return item
            })
            sessionStorage.setItem("cart", JSON.stringify(newCart))
            state = newCart
        },
        minus: (state, action) => {
            let newCart;
            newCart = state.filter(item => {
                if (item.productId === action.payload.productId) {
                    if (item.quantity > 1) {
                        item.quantity = item.quantity - 1
                        return item
                    }
                    else {
                        item.quantity = item.quantity - 1;
                        return
                    }
                }
                return item
            })
            sessionStorage.setItem("cart", JSON.stringify(newCart))
            state = newCart
        },
        removeItem: (state, action) => {
            let newCart;
            newCart = state.filter(item => {
                if (item.productId !== action.payload.productId) return item;
            })
            sessionStorage.setItem("cart", JSON.stringify(newCart))
            state = newCart
        },
        clearCart: (state, action) => {
            sessionStorage.removeItem("cart")
            state = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const { addToCart, plus, minus, removeItem, clearCart } = cartSlice.actions


export default cartSlice.reducer