import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserNavHeight, signIn, signOut } from '../../features/auth/authSlice';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const bcrypt = require('bcryptjs');
// const salt = bcrypt.genSaltSync(3);

const AuthContext = createContext();
export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const user = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("")
    const [navH, setNavH] = useState(0)


    useEffect(() => {
        compareToken()
    }, [])

    async function compareToken() {
        const token = await cookies.get("auth")
        const hashToken = await cookies.get("auth-store")
        if (token && hashToken) {
            const check = bcrypt.compareSync(token, hashToken);
            if (!check) {
                cookies.remove("auth", { path: "/" });
                cookies.remove("auth-store", { path: "/" });
            }
        }
        else if (!hashToken) {
            cookies.remove("auth", { path: "/" });
            cookies.remove("auth-store", { path: "/" });
        }

    }

    function userSignIn(user) {
        dispatch(signIn(user))
    }

    function userSignOut(role) {
        dispatch(signOut(role))
    }

    return (
        <AuthContext.Provider value={{ user, token, userSignIn, userSignOut, typo: { searchInput, setSearchInput }, nav: { navH, setNavH } }}>
            {children}
        </AuthContext.Provider>
    )
}






