import axios from "axios"
import Cookies from "universal-cookie"
// let baseUrl = "https://server.lighthousebd.shop"
const baseUrl = process.env.REACT_APP_SERVER_URL


export default function authCheck(response){
    if(response?.data?.status === 401){
        const cookies = new Cookies();
        cookies.remove("auth", {path: "/"});
        cookies.remove("auth-store", {path: "/"});
    }
}