import React, { useEffect, useState } from 'react'
import { getVariantsAPI } from '../../../lib/api/variantsAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default function ProductVariantAdd({ productVariants, setProductVariants }) {
  const [variants, setVariants] = useState({ status: 0, data: [] })
  const [variantName, setVariantName] = useState("")
  const [stock, setStock] = useState(0)
  const [price, setPrice] = useState(1)

  useEffect(() => {
    getVariants()
  }, [])

  async function getVariants() {
    const response = await getVariantsAPI()
    if (response.status === 200) {
      setVariants(response)
      setVariantName(response?.data[0].variantName)
    }
  }

  function addButtonOnClick(e){
    if(variantName && stock && price){
      const check = productVariants.filter(variant => {return variant.variantName === "No Variant"})
      if(productVariants.length === 0 && !check[0]){
        setProductVariants([{variantName, price, stock}])
      }
      else{
        const index = productVariants.findIndex((variant => {return variant.variantName === variantName}))
        console.log("INDX", index)
        if(index !== -1){
          setProductVariants(productVariants => {
            return productVariants.map(variant => {if(variant.variantName === variantName){return {variantName, price, stock}} else return variant})
          })
        }
        else{
          setProductVariants(productVariants => {return [...productVariants, {variantName, price, stock}]})
        }
      }      
    }
  }

  function deleteVariantOnClick(variantName){
    const fdata = productVariants.filter(variant => {return variant.variantName !== variantName})
    setProductVariants([...fdata])
  }

  return (
    <div className='grid grid-cols-12 gap-2 p-3 bg-white border border-slate-300 shadow-md shadow-slate-400 rounded-lg'>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Variants <span className='text-rose-600'>*</span></p>
          <p className="text-rose-600 mb-1 md:mb-0 pr-4">Add variants in order</p>
          <select className="mt-2 border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" onChange={(e) => { setVariantName(e.target.value) }}>
            {variants.status === 200 && typeof variants.data === 'object' && variants.data.map((variant) => ((
              <option key={variant.variantName} value={variant.variantName} selected={variant.variantName === variantName ? true : false} className='p-2'>{variant.variantName}</option>
            )))}
          </select>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6'></div>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Price <span className='text-rose-600'>*</span></p>
          <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0}
            onChange={(e) => { setPrice(e.target.value) }} value={price} />
        </div>
      </div>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Stock <span className='text-rose-600'>*</span></p>
          <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0}
            onChange={(e) => { setStock(e.target.value) }} value={stock} />
        </div>
      </div>
      <div className='col-span-12'>
        <div className="">
          <p>Added Variants - {productVariants.length}</p>
          <div className='flex flex-wrap gap-2'>
            {productVariants.map((variant, index) => ((
              <div key={'prodvar'+index} className='p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg text-sm'>
                  <p>Variant (Size/Other): {variant.variantName}</p>
                  <p>Stock: {variant.stock}</p>
                  <p>Variant Price: {variant.price}</p>
                  <div className='mt-1 flex justify-end'>
                  <button onClick={() => { deleteVariantOnClick(variant.variantName) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faTrash} /></button>
                  </div>
              </div>
            )))}

          </div>
        </div>
      </div>
      <div className='col-span-12'>
        <div className="flex justify-end">
          <button onClick={(e) => {addButtonOnClick(e)}} className="py-2 px-6 text-sm bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 text-base">Add Variant</button>
        </div>
      </div>
    </div>
  )
}
