import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
const CartContext = createContext();

export function useCart() {
    return useContext(CartContext)
}

const initCart = JSON.parse(sessionStorage.getItem("cart")) || []

const reducer = (cart, action) => {
    if (action.type === "addToCart") {
        let newCart;
        if (cart) {
            let filteredCart = cart.filter(item => { if (item.variantId !== action.data.variantId) return item })
            newCart = [...filteredCart, action.data]
        }
        else {
            newCart = [action.data]
        }
        newCart.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
        sessionStorage.setItem("cart", JSON.stringify(newCart))
        return newCart;
    }
    else if (action.type === "plus") {
        let newCart;
        newCart = cart.filter(item => {
            if (item.productId === action.productId && item.variantId === action.variantId && item.quantity < item.stock) {
                item.quantity = item.quantity + 1
            }
            return item
        })
        sessionStorage.setItem("cart", JSON.stringify(newCart))
        return newCart
    }
    else if (action.type === "minus") {
        let newCart;
        newCart = cart.filter(item => {
            if (item.productId === action.productId && item.variantId === action.variantId) {
                if (item.quantity > 1) {
                    item.quantity = item.quantity - 1
                    return item
                }
                else {
                    item.quantity = item.quantity - 1;
                    return
                }
            }
            return item
        })
        sessionStorage.setItem("cart", JSON.stringify(newCart))
        return newCart
    }
    else if (action.type === "removeItem") {
        let newCart;
        newCart = cart.filter(item => {
            if (item.variantId !== action.variantId) return item;
        })
        sessionStorage.setItem("cart", JSON.stringify(newCart))
        return newCart
    }
    else if (action.type === "clearCart") {
        sessionStorage.removeItem("cart")
        return [];
    }
}

export function CartProvider({ children }) {

    const [cart, dispatch] = useReducer(reducer, initCart)
    const [cartTotal, setCartTotal] = useState(0)
    const [cartTotalItems, setCartTotalItems] = useState(0);

    useEffect(() => {
        setCartTotal(0)
        setCartTotalItems(0)
        cart.forEach((item) => {
            setCartTotal(cartTotal => { return cartTotal + (item.price * item.quantity) })
            setCartTotalItems(cartTotalItems => { return cartTotalItems + item.quantity })
        })
    }, [cart])

    async function addItemToCart(newItem) {
        dispatch({ type: "addToCart", data: newItem })
    }

    async function removeItemFromCart(productId, variantId) {
        dispatch({ type: "removeItem", productId, variantId })
    }

    async function changeQuantity(op, productId, variantId) {
        if (op === "plus") dispatch({ type: "plus", productId, variantId })
        else if (op === "minus") dispatch({ type: "minus", productId, variantId })
    }

    async function manageQuantity(op, product) {
        const found = await cart.find(item => (item.productId === product.productId && item.variantId === product.variantId))
        if (!found) {
            product.quantity = 1;
            addItemToCart(product)
        }
        else {
            changeQuantity(op, product.productId, product.variantId)
        }
    }

    async function clearCartItems() {
        dispatch({ type: "clearCart" })
    }


    return (
        <CartContext.Provider value={{ cart, addItemToCart, removeItemFromCart, changeQuantity, manageQuantity, clearCartItems, cartTotal, cartTotalItems }}>
            {children}
        </CartContext.Provider>
    )
}