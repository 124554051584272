import React, { useEffect, useState } from 'react'
import { getProductsAnalysisByOrderAPI } from '../../../../lib/api/productsAPI'

export default function ProductAnalysis({view}) {

    const [prodByOrder, setProdByOrder] = useState({status: 0, data: []})

    useEffect(() => {
        view === "ProductAnalysis" && getProductsAnalysisByOrder()
    })

    async function getProductsAnalysisByOrder(){
        const response = await getProductsAnalysisByOrderAPI()
        setProdByOrder(response)
    }

  return (
    <div className=''>
        <p className='md:text-lg text-center my-2'>Product Analysis</p>
        <div className='bg-slate-50 p-5 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Products which were ordered most by the customers</p>
            <div className=''>
                {prodByOrder?.status === 200 && typeof prodByOrder.data === 'object' && prodByOrder.data.map((data, index) => ((
                    <div key={`prodbyorder-${index}`} className='flex gap-3 py-3 px-4 border-b border-slate-300'>
                        <div className='max-w-[80px] max-h-[80px] rounded-lg overflow-hidden'>
                            <img src={data?.product?.url} className='w-full h-full object-cover object-center'/>
                        </div>
                        <div className='w-full'>
                            <p className='text-base text-rose-500 font-semibold'>Total Order Placed: {data.totalOrder}</p>
                            <p className='text-base text-rose-500 font-semibold'>Total Ordered Quantity: {data.totalQuantity}</p>
                            <p>Code: {data.product.code}</p>
                            <p>Name: {data.product.name}</p>
                            <p>Brand: {data.product.brand}</p>
                            <p>Price: ${data.product.price}</p>
                        </div>
                    </div>
                )))}
            </div>
        </div>
    </div>
  )
}
