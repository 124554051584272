// ------------- PRODUCTS ---------------
import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL

export async function getProductsAPI(){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/products`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getProductsByCategoryAPI(categoryName){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/products/category/${categoryName}`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function updateProductAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/product`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getProductByIdAPI(id){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/product/${id}`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function addProductAPI(formData){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/products`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteProductAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/product`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

// PRODUCT IMAGES START
export async function updateSingleProductImageAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/product/u/image`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function uploadMultipleImagesAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/product/u/images`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteImagesAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/product/u/image`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}
// PRODUCT IMAGES END


// PRODUCT MORE DETAILS
export async function getProductVariantsAPI(productId){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/auth/product/variants/${productId}`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function addProductVariantsAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/product/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function updateProductVariantAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/product/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteProductVariantAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/product/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}


// PRODUCT MORE DETAILS
export async function getProductMoreDetailsAPI(productId){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/auth/product/details/${productId}`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function addProductMoreDetailsAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/product/details`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function updateProductMoreDetailsAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/product/details`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteProductMoreDetailsAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/product/details`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

// PRODUCTS ON ORDERS
export async function getProductsAnalysisByOrderAPI(){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/products/analysis`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}