
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faStar } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import MainTemplate from '../MainTemplate';
import { useCart } from '../../lib/Providers/CartProvider';
import { useAuth } from '../../lib/Providers/AuthProvider';
import { getProductsAPI } from '../../lib/api/productsAPI';
import { useModal } from '../../lib/Providers/ModalProvider';
import CartReminder from '../../components/user/CartReminder';
import ProductsList from '../../components/user/ProductsList';
import Container from '../Container';
import { getCategoriesAPI } from '../../lib/api/categoryAPI';

export default function Home() {

    const { searchInput } = useAuth().typo
    const { showModalBase } = useModal()
    const navigate = useNavigate()
    const [products, setProducts] = useState({ status: 0, data: [] })
    const [categories, setCategories] = useState({ status: 0, data: [] })

    // const items = [
    //     { productId: 101, category: 'Laptop', name: "Dell Xe234", price: 620, stock: 30, ratings: 4, discount: 12, quantity: 0, image_url: "/products/product01.png" },
    //     { productId: 102, category: 'Headphone', name: "Grazer Ze", price: 115, stock: 25, ratings: 3, discount: 10, quantity: 0, image_url: "/products/product02.png" },
    //     { productId: 103, category: 'Laptop', name: "Dell Xe234", price: 450, stock: 35, ratings: 4, discount: 15, quantity: 0, image_url: "/products/product03.png" },
    //     { productId: 104, category: 'Tab', name: "Niscar LenTab", price: 220, stock: 21, ratings: 3, discount: 5, quantity: 0, image_url: "/products/product04.png" },
    //     { productId: 105, category: 'Headphone', name: "Sony Bassboost", stock: 24, price: 320, ratings: 5, discount: 3, quantity: 0, image_url: "/products/product05.png" },
    //     { productId: 106, category: 'Laptop', name: "MSI PowerX", price: 750, stock: 28, ratings: 5, discount: 7, quantity: 0, image_url: "/products/product06.png" },
    //     { productId: 107, category: 'Phone', name: "Samgsung Galaxy", price: 320, stock: 62, ratings: 4, discount: 0, quantity: 0, image_url: "/products/product07.png" },
    //     { productId: 108, category: 'Laptop', name: "Asus Leon", price: 680, ratings: 4, stock: 17, discount: 0, quantity: 0, image_url: "/products/product08.png" },
    //     { productId: 109, category: 'Camera', name: "LeoCam Rome", price: 280, stock: 14, ratings: 3, discount: 6, quantity: 0, image_url: "/products/product09.png" },
    // ]

    useEffect(() => {
        getAllProducts()
        getAllCategories()
        let dateSet = localStorage.getItem("offer-view")
        if (!dateSet) {
            showOffer()
        }
        else if (dateSet !== `${new Date(Date.now()).toDateString()}`) {
            showOffer()
        }
    }, [])

    async function showOffer() {
        const timeout = setTimeout(() => {
            const component = <div>
                <p className='text-xl mb-1'>Get Amazing Offer Discount</p>
                <p className='mb-1'>Hello there we have new amazing offers running please avail them as soon as you can! Offer is very limited!</p>
                <img src='https://img.freepik.com/free-vector/special-offer-creative-sale-banner-design_1017-16284.jpg' className='w-[12vh] h-[12vh]' />
            </div>
            const response = showModalBase(component)
            localStorage.setItem("offer-view", new Date(Date.now()).toDateString())
            clearTimeout(timeout)
        }, 5000)
    }

    async function getAllProducts() {
        const response = await getProductsAPI();
        setProducts(response)
        console.log("ALL PROD LIST", response)
    }

    async function getAllCategories() {
        const response = await getCategoriesAPI();
        setCategories(response)
        console.log("CATEGORY", response)
    }


    return (
        <MainTemplate>
            <CartReminder />
            {!searchInput && (
                <div className='section w-full'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 md:col-span-8'>
                            <div className='w-full overflow-hidden h-[30vh] md:h-[60vh]'>
                                <img className='w-full h-full object-cover object-center' src='https://www.getonecard.app/images/blog/Shopping_Electronics.png' />
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-4'>
                            <div className='w-full overflow-hidden h-[30vh] md:h-[60vh]'>
                                <img className='w-full h-full object-cover object-center' src='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/electronics-appliances-gadgets-sale-flyer-design-template-944debc79ea59bc49acc5ea5d966c978_screen.jpg?ts=1658437877' />
                            </div>
                        </div>

                    </div>
                </div>
            )}
            <Container>
                {!searchInput && (
                    <div className='categories-section bg-gradient-to-r from-violet-500 via-indigo-300 to-fuchsia-500 my-5 py-5 px-3 border border-slate-300 rounded-lg'>
                        <p className='text-2xl font-bold text-slate-200 mb-3'>CATEGORIES</p>
                        <div className='flex flex-wrap gap-1.5 md:gap-3 md:p-1'>
                            {categories.status === 200 && typeof categories.data === 'object' && categories.data.filter(category => {
                                return category.products.length > 0
                            }).map((category, index) => ((
                                <div key={`home-cat-${category.categoryId}-${index}`} onClick={() => { navigate(`/products-category/${category.name}`) }} className='col-span-4 md:col-span-2' >
                                    <div className='p-2 h-full backdrop-blur-3xl bg-white/40 backdrop-brightness-100 rounded-lg cursor-pointer hover:bg-white/60 transition-all duration-300'>
                                        <div className='w-[80px] h-[80px] md:w-[130px] md:h-[130px] bg-white rounded-lg overflow-hidden'>
                                            <img src={category.products[Math.floor(Math.random() * category.products.length)].url} className='w-full h-full object-cover object-center' />
                                        </div>
                                        <div className='flex justify-between items-end pr-0.5'>
                                            <p className='mt-2 text-xs md:text-sm font-semibold text-slate-800'>{category.name}</p>
                                            <p className='text-xs text-slate-800'>{category?.products?.length}</p>
                                        </div>
                                    </div>
                                </div>
                            )))}
                        </div>

                    </div>
                )}

                <div className='py-3'>
                    <p className='text-2xl font-bold text-slate-700'>PRODUCTS</p>
                </div>
            </Container>
            <ProductsList products={products} />
        </MainTemplate>
    )
}
