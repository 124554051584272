import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// AUTH
import { useAuth } from '../../lib/Providers/AuthProvider';
import { adminLoginAPI } from '../../lib/api/adminAPI';

export default function Admin() {

    const {user, userSignIn} = useAuth();
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        if(user && user.role === "admin"){
            navigate('/admin/dashboard')
        }
    }, [])

    async function onSubmit(e){
        e.preventDefault()
        if(!email || !password){
            toast("Enter email and password"); return;
        }
        if(!email.includes("@")){
            toast("Email is not a valid email"); return;
        }   
        
        const data = {email, password}
        const response = await adminLoginAPI(data)
        if(response.status === 200){
            // PROCESS SIGNIN
            userSignIn(response)            
            navigate("/admin/dashboard")
            // PROCESS SIGNIN END
        }
        else{
            toast(response.data)
        }
        
    }

    return (
        <div className='h-screen bg-slate-200 flex flex-col'>
            {/* <div className='abslolute top-0 left-0 right-0 bg-slate-800 p-3 md:p-5' style={{ zIndex: 1 }}>
                <p className='text-base md:text-2xl font-[600] text-center text-slate-200'>Admin Login</p>
            </div> */}
            <div className='bg-slate-800 text-slate-200 h-full flex flex-col justify-center items-center px-2'>
                <form onSubmit={(e) => onSubmit(e)} className="relative w-full max-w-sm p-5 bg-slate-50 rounded-lg">
                    <div className="flex justify-center">
                        <div className="absolute bg-slate-50 shadow-lg text-center text-slate-800 font-bold py-4 px-6 rounded-full" style={{top: -38}}>
                            <p>Admin Login</p>
                        </div>
                    </div>
                    <div className="mb-6 mt-2">
                        <p className="block text-slate-800 font-bold mb-1 md:mb-0 pr-4">Email</p>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="email" />
                    </div>
                    <div className="mb-6 mt-2">
                        <p className="block text-slate-800 font-bold mb-1 md:mb-0 pr-4">Password</p>
                        <input onChange={(e) => setPassword(e.target.value)} value={password} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="password" />
                    </div>                    
                    <div className="w-full mt-8">
                        <button type="submit" className="w-full shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded transition-all duration-300">Login</button>
                    </div>
                </form>

            </div>
        </div>
    )
}
