import Admin from "./pages/Admin/Admin";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home/Home";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Product from "./pages/Product/Product";
import { CartProvider } from "./lib/Providers/CartProvider";
import Cart from "./pages/Cart/Cart";
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "./lib/CustomToast";

import { CustomAuthProvider } from "./lib/Providers/AuthProvider";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import createStore from 'react-auth-kit/createStore';

import UserAuthPage from "./pages/Auth/AuthPage";
import Checkout from "./pages/Checkout/Checkout";
import Favorites from "./pages/Favorites";

import { AuthProvider } from "./lib/Providers/AuthProvider";
import { Provider } from 'react-redux'
import store from './store'
import ModalProvider from "./lib/Providers/ModalProvider";
import Orders from "./pages/Order/Orders";
import ProductsByCategory from "./pages/Product/ProductsByCategory";
import { useEffect } from "react";
import Test from "./pages/Test";

// const store = createStore({
//   authName: '_myauth',
//   authType: 'cookie',
//   cookieDomain: window.location.hostname,
//   cookieSecure: window.location.protocol === 'https:',
// });

export default function App() {

  useEffect(() => {
    // console.log = function() {}
  }, [])

  return (
    <Provider store={store}>
      <AuthProvider>
        <CartProvider>
          <ModalProvider>
            <BrowserRouter>
              <CustomToast></CustomToast>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/product/:productId" element={<Product />}></Route>
                <Route path="/products-category/:categoryName" element={<ProductsByCategory />}></Route>
                <Route path="/cart" element={<Cart />}></Route>
                <Route path="/auth" element={<UserAuthPage />}></Route>
                <Route path="/orders" element={<Orders />}></Route>
                <Route path="/favs" element={<Favorites />}></Route>
                <Route path="/test" element={<Test />}></Route>
                <Route path="/admin" element={<Admin />}></Route>
                <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
                <Route path="/checkout" element={<Checkout />}></Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </ModalProvider>
        </CartProvider>
      </AuthProvider>
    </Provider>
  );
}
