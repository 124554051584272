import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// AUTH
import { useAuth } from '../../lib/Providers/AuthProvider';
import { useCart } from '../../lib/Providers/CartProvider';
import { userLoginAPI } from '../../lib/api/usersAPI';

export default function Login() {
    const {cart} = useCart()
    const {userSignIn} = useAuth();
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    async function onSubmit(e){
        e.preventDefault()
        if(!email || !password){
            toast("Enter email and password"); return;
        }
        if(!email.includes("@")){
            toast("Email is not a valid email"); return;
        }   
        
        const data = {email, password}
        const response = await userLoginAPI(data)
        if(response.status === 200){
            // PROCESS SIGNIN
            console.log(response)
            userSignIn(response) 
            if(cart && cart.length > 0) navigate("/cart")
            else navigate("/")
            // PROCESS SIGNIN END
        }
        else{
            toast(response.data)
        }
        
    }

  return (
    <form onSubmit={(e) => {onSubmit(e)}} className="grid grid-cols-12 gap-1 md:gap-2 p-5 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden">
                <div className='col-span-12'>
                    <div className="mb-3">
                        <p className="text-slate-800 mb-1 md:mb-0 pr-4">Email</p>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="email" />
                    </div>
                </div>
                <div className='col-span-12'>
                    <div className="mb-3">
                        <p className="text-slate-800 mb-1 md:mb-0 pr-4">Password</p>
                        <input onChange={(e) => setPassword(e.target.value)} value={password}  className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="password" />
                    </div>
                </div>

                <div className='col-span-12'>
                    <div className="">
                        <button className="px-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300" type="submit">Log In</button>
                    </div>
                </div>
            </form>
  )
}



