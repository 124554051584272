import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AdminHome from './pages/(Home)/AdminHome'
import ViewProduct from './pages/(Product)/ViewProduct'
import AddProduct from './pages/(Product)/AddProduct'
import { toast } from 'react-toastify'
import ViewOrder from './pages/(Order)/ViewOrder'
// AUTH
import { useAuth } from '../../lib/Providers/AuthProvider'
import AdminSidebar from '../../components/admin/AdminSidebar'
import AdminNavbar from '../../components/admin/AdminNavbar'
import ProductAnalysis from './pages/(Analysis)/ProductAnalysis'

// VIEW REDUCER
const initViewState = {
    view: "AdminHome",
    component: <AdminHome />
}
const reducer = (state, action) => {
    if (action === "AdminHome") {
        sessionStorage.setItem("view", action)
        return { view: action, component: <AdminHome view={"AdminHome"} /> }
    }
    else if (action === "ViewProduct") {
        sessionStorage.setItem("view", action)
        return { view: action, component: <ViewProduct view={action} /> }
    }
    else if (action === "AddProduct") {
        sessionStorage.setItem("view", action)
        return { view: action, component: <AddProduct view={action} /> }
    }
    else if (action === "ViewOrder") {
        sessionStorage.setItem("view", action)
        return { view: action, component: <ViewOrder view={action} /> }
    }
    else if (action === "ProductAnalysis") {
        sessionStorage.setItem("view", action)
        return { view: action, component: <ProductAnalysis view={action} /> }
    }
}

// SIDEBAR DROPS REDUCER
const initSidebarDrops = JSON.parse(sessionStorage.getItem("sidebar")) || { products: true, orders: true, analysis: false }
const SidebarDropsReducer = (state, action) => {
    if (action === "toggleProducts") {
        const newState = { ...state, products: !state.products }
        sessionStorage.setItem("sidebar", JSON.stringify(newState))
        return newState
    }
    else if (action === "toggleOrders") {
        const newState = { ...state, orders: !state.orders }
        sessionStorage.setItem("sidebar", JSON.stringify(newState))
        return newState
    }
    else if (action === "toggleAnalysis"){
        const newState = { ...state, analysis: !state.analysis }
        sessionStorage.setItem("sidebar", JSON.stringify(newState))
        return newState
    }
}

export default function AdminDashboard() {

    const { user, userSignOut } = useAuth()
    const navigate = useNavigate()
    const breakdown = 900;
    const sidebarWidth = 270;
    const [screenWidth, setScreenWidth] = useState(0)
    const [sidebar, setSidebar] = useState(true)
    // const [sidebarDrops, setSidebarDrops] = useState(JSON.parse(sessionStorage.getItem("sidebar")) || { home: true, products: true, orders: false })
    const [dropNav, setDropNav] = useState(false)
    const [simpleMsg, setSimpleMsg] = useState("")
    const [sidebarDropsState, dispatchSidebarDrops] = useReducer(SidebarDropsReducer, initSidebarDrops);
    // VIEW
    const [viewState, dispatchView] = useReducer(reducer, initViewState);

    function settingSidebar() {
        if (window.innerWidth < breakdown) setSidebar(false);
        else setSidebar(true);
    }
    function resizing() {
        setScreenWidth(window.innerWidth)
        settingSidebar()
    }
    useEffect(() => {
        user?.role !== "admin" && navigate('/admin')
        toast.dismiss()
        setScreenWidth(window.innerWidth)
        settingSidebar()

        // INITVIEW
        let view = sessionStorage.getItem("view")
        if (view) dispatchView(view);

        window.addEventListener('resize', resizing)
        return (() => {
            window.removeEventListener('resize', resizing)
        })
    }, [])


    if (user?.role !== "admin") {
        return (
            <div className='h-screen bg-slate-800 text-slate-200 flex flex-col justify-center items-center'>
                <p className='text-lg'>Permission not allowed to view this page</p>
            </div>
        )
    }

    return (
        <div className='min-h-screen relative bg-slate-200 text-slate-200 flex text-sm md:text-base'>

            <AdminSidebar sidebarWidth={sidebarWidth} sidebar={sidebar} viewState={viewState.view} dispatchView={dispatchView} sidebarDropsState={sidebarDropsState} dispatchSidebarDrops={dispatchSidebarDrops} />

            <div className='whole w-full h-full flex flex-col text-slate-800 text-sm transition-all duration-300' style={{ marginLeft: screenWidth > breakdown && sidebar ? sidebarWidth : 0 }}>
                <div className='sticky top-0 right-0 left-0 shadow-sm shadow-slate-300' style={{ zIndex: 1 }}>
                    <AdminNavbar dropNav={dropNav} setDropNav={setDropNav} setSidebar={setSidebar} sidebar={sidebar} user={user} userSignOut={userSignOut} dispatchView={dispatchView} />
                </div>
                <div className='main min-h-[100vh] p-2 mb-14 relative h-full text-slate-800'>
                    <div className='hard-center-main h-full w-full md:w-10/12 lg:w-8/12 mx-auto'>
                        {viewState.component && viewState.component}
                    </div>
                </div>
                <div className='footer min-h-[15vh] bg-slate-800 text-slate-200'>
                    <div className='h-full w-full md:w-10/12 lg:w-8/12 mx-auto'>
                        <div className='grid grid-cols-12'>
                            <div className='col-span-12 md:col-span-4 flex flex-col items-center'>
                                <div className='mt-3 p-2'>
                                    <p>An admin can edit documents and make changes to information. Please note that there are some changes which cannot be restored or altered such as changing the status for cancelled orders.</p>
                                </div>
                            </div>
                            <div className='col-span-12 md:col-span-4 flex flex-col items-center'>
                                <div className='mt-3 p-2'>
                                    <p>This is a footer section</p>
                                    <p>This is a footer section</p>
                                    <p>This is a footer section</p>
                                </div>
                            </div>
                            <div className='col-span-12 md:col-span-4 flex flex-col items-center'>
                                <div className='mt-3 p-2'>
                                    <p>This is a footer section</p>
                                    <p>This is a footer section</p>
                                    <p>This is a footer section</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
