import React, { useEffect, useState } from 'react'
import { getVariantsAPI } from '../../../lib/api/variantsAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { addProductVariantsAPI, deleteProductVariantAPI, getProductVariantsAPI, updateProductVariantAPI } from '../../../lib/api/productsAPI'

export default function ProductVariantUpdate({ productId, prodVariants }) {
  const [variants, setVariants] = useState({ status: 0, data: [] })
  const [productVariants, setProductVariants] = useState({ status: 200, data: prodVariants })
  const [variantId, setVariantId] = useState("")
  const [variantName, setVariantName] = useState("")
  const [stock, setStock] = useState(0)
  const [price, setPrice] = useState(1)
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    getVariants()
  }, [])

  async function getVariants() {
    const response = await getVariantsAPI()
    if (response.status === 200) {
      setVariants(response)
      setVariantName(response?.data[0].variantName)
    }
  }

  async function getProductVariants() {
    const response = await getProductVariantsAPI(productId)
    if (response.status === 200) {
      setProductVariants(response)
    }
  }


  async function addButtonOnClick(e) {
    if (!variantName && !stock && !price && !productId) {
      return
    }
    if (productVariants.data.length > 0 && variantName === 'No Action') {
      return
    }
    const fdata = productVariants.data.filter(variant => { if (variantName === variant.variantName) return variant })
    if (fdata[0]) {
      return
    }
    const data = { variantName, stock, price, productId }
    const response = await addProductVariantsAPI(data)
    if (response.status === 200) {
      getProductVariants()
    }
  }

  function updateOnClick(variant) {
    setIsUpdate(true)
    setVariantId(variant.variantId)
    setVariantName(variant.variantName)
    setPrice(variant.price)
    setStock(variant.stock)
  }

  async function confirmUpdate(e) {
    if (!variantName && !stock && !price && !productId) {
      return
    }
    const data = { variantId, variantName, stock, price, productId }
    const response = await updateProductVariantAPI(data)
    if (response.status === 200) {
      getProductVariants()
      setIsUpdate(false)
    }
  }

  async function deleteVariantOnClick(variantId, productId) {
    if (productVariants.data.length === 1) {return}
    const data = { variantId, productId }
      const response = await deleteProductVariantAPI(data)
      if (response.status === 200) {
        getProductVariants()
      }

  }

  return (
    <div className='grid grid-cols-12 gap-2 p-3 bg-white border border-slate-300 shadow-md shadow-slate-400 rounded-lg'>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Variants <span className='text-rose-600'>*</span></p>
          <p className="text-rose-600 mb-1 md:mb-0 pr-4">Add variants in order</p>
          <select className="mt-2 border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" onChange={(e) => { setVariantName(e.target.value) }}>
            {variants.status === 200 && typeof variants.data === 'object' && variants.data.map((variant) => ((
              <option key={variant.variantName} value={variant.variantName} selected={variant.variantName === variantName ? true : false} className='p-2'>{variant.variantName}</option>
            )))}
          </select>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6'></div>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Price <span className='text-rose-600'>*</span></p>
          <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0}
            onChange={(e) => { setPrice(e.target.value) }} value={price} />
        </div>
      </div>
      <div className='col-span-12 md:col-span-6'>
        <div className="mb-3">
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Stock <span className='text-rose-600'>*</span></p>
          <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0}
            onChange={(e) => { setStock(e.target.value) }} value={stock} />
        </div>
      </div>
      <div className='col-span-12'>
        <div className="">
          <p>Added Variants - {productVariants?.data && productVariants.data.length}</p>
          <div className='flex flex-wrap gap-2'>
            {productVariants.status === 200 && typeof productVariants.data === 'object' && productVariants.data.map((variant) => ((
              <div key={variant.variantId} className='p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg text-sm'>
                <p>Variant (Size/Other): {variant.variantName}</p>
                <p>Stock: {variant.stock}</p>
                <p>Variant Price: {variant.price}</p>
                <div className='mt-1 flex gap-1 flex-wrap justify-end'>
                  <button onClick={(e) => { updateOnClick(variant) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faPen} /></button>
                  <button onClick={() => { deleteVariantOnClick(variant.variantId, variant.productId) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faTrash} /></button>
                </div>
              </div>
            )))}

          </div>
        </div>
      </div>
      <div className='col-span-12'>
        <div className="flex justify-end">
          {isUpdate ? (
            <div className='flex gap-2 flex-wrap'>
              <button onClick={(e) => { setIsUpdate(!isUpdate) }} className="py-2 px-6 text-sm bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 text-base">Cancel</button>
              <button onClick={(e) => { confirmUpdate(e) }} className="py-2 px-6 text-sm bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 text-base">Update Variant</button>
            </div>
          ) : (
            <button onClick={(e) => { addButtonOnClick(e) }} className="py-2 px-4 text-sm bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 text-base">Add Variant</button>
          )}

        </div>
      </div>
    </div>
  )
}
