import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../../lib/Providers/AuthProvider';
import { changeOrderStatusAPI, getOrdersAPI } from '../../../../lib/api/ordersAPI';
import { toast } from 'react-toastify';

export default function ViewOrder() {

  const { user } = useAuth()
  const [orders, setOrders] = useState({ status: 0, data: [] });
  const [statusId, setStatusId] = useState(-1)
  const [searchInput, setSearchInput] = useState("");
  const [orderStatusCount, setOrderStatusCount] = useState({pending: 0, processing: 0, pickedUp: 0, shipped: 0, delivered: 0, cancelled: 0})
  const statusArr = ["pending", "processing", "picked up", "shipped", "delivered", "cancelled"]
  // const [fetchMore, setFetchMore] = useState(false)
  // const [page, setPage] = useState(1)
  const itemsPerPage = 3
  const [sliceCount, setSliceCount] = useState(itemsPerPage)
  const obsRef = useRef(null)


  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     console.log("Hello intersecting", entries[0].isIntersecting)
  //     if(entries[0].isIntersecting){
  //       if(page === 1){
  //         setFetchMore(true);
  //         setPage(page+1)
  //         console.log("INITIAL PAGE NO", page)
  //       }
  //       else if(page > 1 && fetchMore){
  //         setPage(page+1)
  //         getAllOrders()
  //         console.log("PAGE NO", page, "FETCH MORE", fetchMore)
  //       }
  //     }
  //   })
  //   if(observer && obsRef.current){
  //     observer.observe(obsRef.current)
  //   }
  //   return (() => {
  //     if(observer) observer.disconnect()
  //   })
  // }, [fetchMore, page])

  useEffect(() => {
    getAllOrders()
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("Hello intersecting", entries[0].isIntersecting)
      if (entries[0].isIntersecting && sliceCount <= orders?.data?.length) {
        setSliceCount(sliceCount + itemsPerPage)
      }
    })
    if (observer && obsRef.current) {
      observer.observe(obsRef.current)
    }
    return (() => {
      if (observer) observer.disconnect()
    })
  }, [sliceCount, orders, searchInput])

  async function getAllOrders() {
    if (!user) return;
    const response = await getOrdersAPI();
    setOrders(response)
    countOrderStatus(response)
    console.log(response)
  }

  function countOrderStatus(orders){
    setOrderStatusCount({pending: 0, processing: 0, pickedUp: 0, shipped: 0, delivered: 0, cancelled: 0})
    if(orders.status === 200 && orders?.data?.length > 0){
      orders.data.forEach(order => {
        switch(order.status){
          case "pending": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, pending: orderStatusCount.pending + 1}
          }); break;
          case "processing": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, processing: orderStatusCount.processing + 1}
          }); break;
          case "picked up": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, pickedUp: orderStatusCount.pickedUp + 1}
          }); break;
          case "shipped": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, shipped: orderStatusCount.shipped + 1}
          }); break;
          case "delivered": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, delivered: orderStatusCount.delivered + 1}
          }); break;
          case "cancelled": 
          setOrderStatusCount(orderStatusCount => {
            return {...orderStatusCount, cancelled: orderStatusCount.cancelled + 1}
          }); break;
        }
      })
    }
  }

  // async function getAllOrders() {
  //   let response;
  //   if(page === 1){
  //     response = await getOrdersAPI({skip: 0, take: itemsPerPage});
  //     setOrders(orders => {
  //       return {status: response.status, data: [...orders.data, ...response.data]}
  //     })
  //   }
  //   else if(page > 1 && fetchMore){
  //     response = await getOrdersAPI({skip: (page*itemsPerPage)-itemsPerPage, take: page*itemsPerPage});
  //     console.log("RESPONSE",response)
  //     if(response.data.length !== 0) {
  //       setOrders(orders => {
  //         return {status: response.status, data: [...orders.data, ...response.data]}
  //       })
  //     }
  //     else{
  //       setFetchMore(false);
  //     }      
  //   }
  // }

  async function handleOrderStatusChange(e, orderId, products) {
    setStatusId(-1);
    console.log("PROD", products)
    const response = await changeOrderStatusAPI({ orderId, status: e.target.value, products: products })
    console.log("RES", response)
    if (response.status === 200) {
      console.log("CHANGED DATA RESPONSE", response.data)
      toast(`Order status changed to ${capitalizeFirstLetter(e.target.value)} | Order id - ${orderId}`)
      getAllOrders()
    }
  }

  function getStatusPercentage(status) {
    if (status === "pending") return 0
    else if (status === "processing") return 25
    else if (status === "picked up") return 50
    else if (status === "shipped") return 75
    else if (status === "delivered" || status === "cancelled") return 100;
  }

  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  function matchExact(str1, str2) {
    var match = str1.match(str2);
    return match && str1 === match[0];
  }
  return (
    <div>
      <div className='min-h-screen p-1'>
      <p className='md:text-lg text-center my-2'>View All Orders</p>
        {orders.status===200 && orders?.data?.length === 0 && <p className='my-1'>No orders to display</p>}
        <div className='sticky top-16 w-full bg-slate-50 p-2 md:p-3 md:text-base md:font-semibold flex gap-2 flex-wrap border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
          <div className='bg-sky-200 p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Pending</p>
            <p>{orderStatusCount.pending}</p>
          </div>
          <div className='p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Processing</p>
            <p>{orderStatusCount.processing}</p>
          </div>
          <div className='p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Picked Up</p>
            <p>{orderStatusCount.pickedUp}</p>
          </div>
          <div className='p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Shipped</p>
            <p>{orderStatusCount.shipped}</p>
          </div>
          <div className='ml-auto bg-violet-200 p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Delivered</p>
            <p>{orderStatusCount.delivered}</p>
          </div>
          <div className='bg-rose-200 p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p>Cancelled</p>
            <p>{orderStatusCount.cancelled}</p>
          </div>
        </div>
        <div className='w-full my-1 pt-2 pb-3'>
          <p className='text-sm'>Search Order by - <span className='text-purple-500 font-semibold'>Order Id, User Email or Phone</span></p>
          <div className='flex gap-2'>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setSearchInput(e.target.value) }} value={searchInput} />
            <button className="py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Search</button>
          </div>
        </div>
        <div className='w-full flex flex-col'>
          {orders.status === 200 && typeof orders.data === "object" && orders.data.length > 0 && orders.data.filter(order => {
            if (order.orderId.toString().toLowerCase().includes(searchInput.toLowerCase()) || order?.user?.email.toLowerCase().includes(searchInput.toLowerCase())
              || order?.user?.phone.toLowerCase().includes(searchInput.toLowerCase())) { return order }
          }).slice(0, sliceCount).map((order, index) => ((
            <div key={order.orderId} className='cart-item bg-slate-50 mb-3 flex flex-col p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>

              <div className='avatar-profile mb-1 w-[60px] h-[60px] overflow-hidden rounded-full'>
                <img className='w-full h-full object-cover object-center' src={order?.user?.image} />
              </div>
              <div className='grid grid-cols-12 gap-2 md:gap-0 border-b border-slate-300'>
                <div className='col-span-12 md:col-span-6 mb-2'>
                  <div className=''>
                    <p className='text-lg'>Billing Details</p>
                    <p className='text-sm'>Name: {order.user.fname} {order.user.lname}</p>
                    <p className='text-sm'>Email: {order.user.email}</p>
                    <p className='text-sm'>Delivery Addresss: {order.deliveryAddress} </p>
                    <p className='text-sm'>Payment Method: {order.paymentMethod} </p>
                    <p className='text-sm'>City: {order.user.city}</p>
                    <p className='text-sm'>Number: {order.user.phone}</p>
                  </div>
                </div>
                <div className='col-span-12 md:col-span-6'>
                  <div>
                    <p className='text-lg font-semibold mb-1'>Order Id: {order.orderId} </p>
                    <p className='text-sm'>Order Date: {new Date(order.date).toUTCString()} </p>
                    <p className='text-sm'>Additional Fee: {order.additionalFee} </p>
                    <p className='text-sm'>Discount Fee: {order.discountFee} </p>
                    <p className='text-sm'>Delivery Fee: {order.deliveryFee} </p>
                    <p className='text-sm'>Total Price: {order.totalPrice} </p>
                  </div>
                  <div className='tracker w-full md:w-7/12 lg:w-8/12 my-2 bg-slate-100 border border-slate-300 shadow-md shadow-slate-300 p-3 rounded-lg'>
                    <p className='text-sm mb-1.5'>Order Status: <span className={`${order.status === 'delivered' ? 'text-green-600' : order.status === 'cancelled' ? 'text-rose-600' : 'text-purple-600'}`}>{capitalizeFirstLetter(order.status)}</span> </p>
                    <div className="flex w-full h-2 bg-slate-300 rounded-full overflow-hidden" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                      <div className={`flex flex-col justify-center rounded-full overflow-hidden text-xs text-white text-center whitespace-nowrap transition duration-500 ${order.status === 'delivered' ? 'bg-green-600' : order.status === 'cancelled' ? 'bg-rose-600' : 'bg-purple-600'}`} style={{ width: `${getStatusPercentage(order.status)}%` }}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex flex-wrap gap-2 pt-2'>
                {order.products.map(product => ((
                  <div className='bg-violet-100 flex flex-col justify-center p-2 rounded-lg'>
                    <div className='w-[80px] h-[80px] overflow-hidden rounded-lg mb-1'>
                      <img className='w-full h-full object-cover object-center' src={product.url} />
                    </div>
                    <div>
                      <p className='text-xs font-semibold'>: {product.code}</p>
                      <p className='text-xs font-semibold'>{product.name}</p>
                      <p className='text-xs font-semibold'>Variant: {product.variantName}</p>
                      <p className='text-xs'>x{product.quantity}</p>
                      <p className='text-xs'>Price: {product.itemPrice}</p>
                      <p className='text-xs'>Total: {product.quantity * product.itemPrice}</p>
                    </div>
                  </div>
                )))}
              </div>

              <div className='w-full mt-2 flex flex-wrap justify-end items-center gap-2'>
                {statusId === order.orderId && (
                  <select onChange={(e) => { handleOrderStatusChange(e, order.orderId, order.products) }} defaultValue={order.status} className='py-2 px-1 bg-slate-100 text-slate-800 border border-slate-300 rounded shadow-sm shadow-slate-300 transition-all duration-300'>
                    {statusArr.map((status) => ((
                      <option className='py-2 px-4' value={status} >{capitalizeFirstLetter(status)}</option>
                    )))}
                  </select>
                )}
                {order.status === 'cancelled' ? (
                  <p className='text-rose-500'>Cannot change the order status which is Cancelled.</p>
                ) :
                  (
                    <button onClick={(e) => { statusId === -1 || statusId !== order.orderId ? setStatusId(order.orderId) : setStatusId(-1); }} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Change Status</button>
                  )
                }

              </div>
            </div>
          )))}
        </div>
      </div>
        <div ref={obsRef} className='m-1 bg-white py-5 flex justify-center items-center border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
          <p>You've reached at the end of the page</p>
        </div>
    </div>
  )
}
