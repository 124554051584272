import React, { useEffect, useRef, useState } from 'react'
import MainTemplate from '../MainTemplate'
import { changeOrderStatusAPI, getOrdersAPI } from '../../lib/api/ordersAPI';
import { useAuth } from '../../lib/Providers/AuthProvider';
import Container from '../Container';
import { toast } from 'react-toastify';
import { useModal } from '../../lib/Providers/ModalProvider';
import { useNavigate } from 'react-router-dom';

export default function Orders() {
    const navigate = useNavigate()
    const { user } = useAuth()
    const { showModalConfirm } = useModal()
    const [orders, setOrders] = useState({ status: 0, data: [] });
    const itemsPerPage = 3
    const [sliceCount, setSliceCount] = useState(itemsPerPage)
    const obsRef = useRef(null)

    useEffect(() => {
        if (!user && user?.role !== 'user') navigate('/auth')
        window.scrollTo(0, 0)
        getAllOrders()
    }, [])

    async function getAllOrders() {
        if (!user) return;
        const response = await getOrdersAPI({ userId: user.userId });
        setOrders(response)
        console.log(response)
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log("Hello intersecting", entries[0].isIntersecting)
            if (entries[0].isIntersecting && sliceCount <= orders?.data?.length) {
                setSliceCount(sliceCount + itemsPerPage)
            }
        })
        if (observer && obsRef.current) {
            observer.observe(obsRef.current)
        }
        return (() => {
            if (observer) observer.disconnect()
        })
    }, [sliceCount, orders])

    async function handleOrderStatusChange(orderId, products) {
        const modalResponse = await showModalConfirm({ title: "Cancel Order", body: "Are you sure you want to cancel your order? Click Yes to cancel." });
        if (modalResponse === 'confirm') {
            const response = await changeOrderStatusAPI({ orderId, status: "cancelled", products: products })
            console.log("RES", response)
            if (response.status === 200) {
                toast(`Order cancelled order id ${orderId}`)
                getAllOrders()
            }
        }

    }

    function getStatusPercentage(status) {
        if (status === "pending") return 0
        else if (status === "processing") return 25
        else if (status === "picked up") return 50
        else if (status === "shipped") return 75
        else if (status === "delivered" || status === "cancelled") return 100;
    }

    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    return (
        <MainTemplate>
            <Container>
                <div className='min-h-screen w-full md:w-10/12 mx-auto'>
                    <div className='p-1 flex flex-col justify-center items-center'>
                        <p className='md:text-2xl mb-2'>Your Orders</p>
                        {orders.data.length === 0 && <p className='my-1'>You have no placed orders</p>}
                        <div className='w-full'>
                            {orders.status === 200 && typeof orders.data === "object" && orders.data.length > 0 && orders.data.slice(0, sliceCount).map(order => ((
                                <div key={order.orderId} className='cart-item bg-white mb-3 flex flex-col p-2.5 border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                    <p>Order Id: {order.orderId} </p>
                                    <p className='text-sm'>Order Date: {new Date(order.date).toUTCString()} </p>
                                    <p className='text-sm'>Delivery Addresss: {order.deliveryAddress} </p>
                                    <p className='text-sm'>Payment Method: {order.paymentMethod} </p>
                                    <p className='text-sm'>Delivery Fee: {order.deliveryFee} </p>
                                    <p className='text-sm'>Additional Fee: {order.additionalFee} </p>
                                    {order.discountFee > 0 && <p className='text-sm'>Discount Fee: -{order.discountFee} </p>}
                                    <p className='text-sm'>Total Price: {order.totalPrice} </p>

                                    <div className='tracker w-full md:w-6/12 lg:w-3/12 my-3 bg-slate-50 border border-slate-300 shadow-md shadow-slate-300 p-3 rounded-lg'>
                                        <p className='text-sm'>Track Your Order</p>
                                        <p className='text-sm mb-1.5'>Order Status: <span className={`${order.status === 'delivered' ? 'text-green-600' : order.status === 'cancelled' ? 'text-rose-500' : 'text-purple-600'}`}>{capitalizeFirstLetter(order.status)}</span> </p>
                                        <div className="flex w-full h-2 bg-slate-300 rounded-full overflow-hidden" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                            <div className={`flex flex-col justify-center rounded-full overflow-hidden text-xs text-white text-center whitespace-nowrap transition duration-500 ${order.status === 'delivered' ? 'bg-green-600' : order.status === 'cancelled' ? 'bg-rose-600' : 'bg-purple-600'}`} style={{ width: `${getStatusPercentage(order.status)}%` }}></div>
                                        </div>
                                    </div>

                                    <div className='flex flex-wrap gap-2'>
                                        {order.products.map(product => ((
                                            <div className='bg-violet-100 flex flex-col justify-center p-2 rounded-lg'>
                                                <div className='w-[80px] h-[80px] overflow-hidden rounded-lg mb-1'>
                                                    <img className='w-full h-full object-cover object-center' src={product?.url} />
                                                </div>
                                                <div>
                                                    <p className='text-xs font-semibold'>{product?.name}</p>
                                                    <p className='text-xs'>Variant: {product?.variantName}</p>
                                                    <p className='text-xs'>x{product?.quantity}</p>
                                                    <p className='text-xs'>Price: {product?.itemPrice}</p>
                                                    <p className='text-xs'>Total: {product?.quantity * product?.itemPrice}</p>
                                                </div>
                                            </div>
                                        )))}
                                    </div>
                                    <div onClick={() => { handleOrderStatusChange(order.orderId, order.products) }} className='mt-2 flex justify-end'>
                                        <button className='text-sm py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 disabled:bg-slate-200 disabled:text-slate-400' disabled={order.status === "pending" || order.status === "processing" ? false : true}>Cancel Order</button>
                                    </div>

                                </div>
                            )))}
                        </div>
                    </div>
                </div>
                <div ref={obsRef}></div>
            </Container>

        </MainTemplate>
    )
}
