import React, { createContext, useContext, useRef, useState } from 'react';
import ModalConfirm, { ModalBase } from '../../components/user/Modal';
const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext)
}

export default function ModalProvider({ children }) {

    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [isShowBase, setIsShowBase] = useState(false);
    const [modalData, setModalData] = useState({title: null, body: null});
    const promiseRef = useRef();
    const [component, setComponent] = useState(null)


    const showModalConfirm = (data) => {
        setIsShowConfirm(true);
        setModalData(data);

        const promise = new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        })
        return promise
    }
    const onCloseConfirm = (response) => {
        setIsShowConfirm(false);
        setModalData({title: null, body: null})
        if (promiseRef.current) {
            promiseRef.current.resolve(response)
        }
        promiseRef.current = null
    }

    // BASE
    const showModalBase = (data) => {
        setIsShowBase(true);
        setComponent(data);

        const promise = new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        })
        return promise
    }
    const onCloseBase = (response) => {
        setIsShowBase(false);
        setComponent(null);
        if (promiseRef.current) {
            promiseRef.current.resolve(response)
        }
        promiseRef.current = null
    }


    return (
        <ModalContext.Provider value={{ showModalConfirm, showModalBase }}>
            <ModalConfirm isShowConfirm={isShowConfirm} setIsShowConfirm={setIsShowConfirm} modalData={modalData} onCloseConfirm={onCloseConfirm} />
            <ModalBase isShowBase={isShowBase} setIsShowBase={setIsShowBase} component={component} onCloseBase={onCloseBase} />
            {children}
        </ModalContext.Provider>
    )
}
