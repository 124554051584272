import React, { useEffect, useRef, useState } from 'react'
import MainTemplate from './MainTemplate'

export default function Test() {
    const [posts, setPosts] = useState(null)
    const itemsPerPage = 30
    const [sliceCount, setSliceCount] = useState(0)
    const obsRef = useRef(null)


    useEffect(() => {
        getPosts()
    }, [])

    async function getPosts() {
        const url = 'https://jsonplaceholder.typicode.com/photos'
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const json = await response.json();
            console.log(json);
            setPosts(json)
        } catch (error) {
            console.error(error.message);
        }


    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log("Hello intersecting", entries[0].isIntersecting)
            if (entries[0].isIntersecting && sliceCount <= posts?.length) {
                setSliceCount(sliceCount + itemsPerPage)
            }
        })
        if (observer && obsRef.current) {
            observer.observe(obsRef.current)
        }
        return (() => {
            if (observer) observer.disconnect()
        })
    }, [sliceCount, posts])

    return (
        <MainTemplate>

            <div className='flex flex-wrap gap-3 mx-auto'>
                {posts && typeof posts === 'object' && posts.slice(0, sliceCount).map((each) => ((
                    <div className='w-[150px] p-1.5 border border-slate-300 shadow shadow-slate-300 rounded-lg'>
                        <div className='overflow-hidden rounded-md'>
                            <img src={each.url} className='h-full w-full object-cover object-center' />
                        </div>
                        <p className='text-xs'>{each.title}</p>
                    </div>
                )))}

            </div>

            <div ref={obsRef} className='m-1 bg-white py-5 flex justify-center items-center border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                <p>You've reached at the end of the page</p>
            </div>
        </MainTemplate>
    )
}
