import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faBars } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';



export default function AdminNavbar({ dropNav, setDropNav, setSidebar, sidebar, user, userSignOut, dispatchView }) {
    const navigate = useNavigate()


    async function logOut() {
        sessionStorage.removeItem("sidebar")
        sessionStorage.removeItem("view")
        userSignOut("admin")
        navigate('/admin')
        navigate(0)
    }

    return (
        <div className='navbar flex justify-between items-center bg-white'>
            <div className='profile relative py-2.5 px-3 cursor-pointer'>
                {user && user.role === "admin" && (
                    <div className='flex items-center gap-2' onClick={(e) => { setDropNav(!dropNav) }}>
                        <div>
                            <img src={user.image} className='w-12 h-12 object-cover object-center rounded-full' />
                        </div>
                        <div className='h-full flex flex-col justify-center'>
                            <p className='text-sm text-slate-600 font-medium'>{user.name}</p>
                            <p className='text-xs text-slate-600 font-medium'>{user.email}</p>
                        </div>
                        <div className='text-slate-400'>
                            <FontAwesomeIcon icon={!dropNav ? faAngleDown : faAngleUp} size='lg' />
                        </div>
                    </div>
                )}
                <div className={`${!dropNav ? 'fade-out' : 'fade-in'} absolute w-[150px] md:w-[200px] bg-white shadow-md mt-3 rounded-lg overflow-hidden`} style={{ zIndex: 2 }}>
                    <button onClick={() => {dispatchView("AdminHome"); setDropNav(false)}} className='w-full block text-xs md:text-sm border-b border-slate-300 text-slate-600 p-2 hover:bg-purple-600 hover:text-slate-200 transition-all duration-300'>Home</button>
                    <button className='w-full block text-xs md:text-sm border-b border-slate-300 text-slate-600 p-2 hover:bg-purple-600 hover:text-slate-200 transition-all duration-300'>About</button>
                    <button onClick={() => { logOut() }} className='w-full block text-xs md:text-sm border-b border-slate-300 text-slate-600 p-2 hover:bg-purple-600 hover:text-slate-200 transition-all duration-300'>Logout</button>
                </div>
            </div>
            <div className='h-full px-3 flex flex-col justify-center'>
                <button onClick={() => setSidebar(!sidebar)} className='p-3 border-2 border-slate-300 text-slate-300 hover:border-slate-400 hover:text-slate-400 rounded-lg ' >
                    <FontAwesomeIcon icon={faBars} size='2xl' />
                </button>
            </div>
        </div>
    )
}
