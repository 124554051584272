import React, { useEffect } from 'react'

export default function ModalConfirm({ isShowConfirm, setIsShowConfirm, modalData, onCloseConfirm }) {

    useEffect(() => {
        document.addEventListener("click", e => {
            if (e.target.classList.contains("modal-component-whole")) {
                setIsShowConfirm(false);
            }
        });
    }, [])

    return (
        <div className={`modal-component-whole fixed top-0 left-0 right-0 bottom-0 z-20 flex flex-col justify-center items-center bg-slate-800 bg-opacity-40 ${!isShowConfirm && 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className='bg-slate-50 flex flex-col gap-2 p-5 mx-2 rounded-sm shadow-lg shadow-slate-500 rounded-lg overflow-hidden'>
                {/* <p>title</p> */}
                <p className='text-base md:text-xl mb-1'>{modalData?.title} </p>
                <p className='text-sm md:text-md mb-1'>{modalData?.body} </p>
                <div className="bg-slate-50 flex gap-2 my-1">
                    <button onClick={() => { onCloseConfirm("confirm") }} type="button" className="px-5 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300">Yes</button>
                    <button onClick={() => { onCloseConfirm("reject") }} type="button" className="px-3 bg-rose-700 hover:bg-rose-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300">Cancel</button>
                </div>
            </div>

        </div>
    )
}

export function ModalBase({ isShowBase, setIsShowBase, component, onCloseBase }) {

    useEffect(() => {
        document.addEventListener("click", e => {
            if (e.target.classList.contains("modal-component-whole")) {
                setIsShowBase(false);
            }
        });
    }, [])
    
    return (
        <div className={`modal-component-whole fixed top-0 left-0 right-0 bottom-0 z-20 flex flex-col justify-center items-center bg-slate-800 bg-opacity-40 ${!isShowBase && 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className='bg-slate-50 flex flex-col gap-2 p-3 mx-2 rounded-sm shadow-lg shadow-slate-500 rounded-lg overflow-hidden'>
                <div className='py-2'>
                    {component && component}
                </div>
                <div className="bg-slate-50 flex gap-2 justify-end">
                    <button onClick={() => { onCloseBase("close") }} type="button" className="px-5 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300">Close</button>
                </div>
            </div>

        </div>
    )
}
