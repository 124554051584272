import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { deleteImagesAPI, deleteProductAPI, updateProductAPI, updateSingleProductImageAPI, uploadMultipleImagesAPI } from '../../../../lib/api/productsAPI';
import MoreDetailsSection from '../../../../components/admin/Product/MoreDetailsSection';
import { getCategoriesAPI } from '../../../../lib/api/categoryAPI';
import ProductVariantUpdate from '../../../../components/admin/Product/ProductVariantUpdate';

export default function UpdateProduct({ editItem, setUpdate, getAllProducts }) {
  const item = editItem;
  const navigate = useNavigate();
  const productId = item.productId
  const [code, setCode] = useState(item.code)
  const [name, setName] = useState(item.name)
  const [brand, setBrand] = useState(item.brand)
  const [categoryId, setCategoryId] = useState(item.category.categoryId)
  const [price, setPrice] = useState(item.price)
  const [description, setDescription] = useState(item.description)
  const [longDescription, setLongDescription] = useState(item.longDescription)
  const [discount, setDiscount] = useState(item.discount)
  const [extraDetails, setExtraDetails] = useState(item.moreDetails)
  const [selectedImages, setSelectedImages] = useState(null)
  const [categories, setCategories] = useState({ status: 0, data: [] })
  const [productVariants, setProductVariants] = useState(item.variants)

  // UPDATES
  const [swtichTabs, setSwitchTabs] = useState("fields");
  const [singleImage, setSingleImage] = useState(null)
  const [isEditImagesTab, setIsEditImagesTab] = useState(true);
  const [radioSelectedImage, setRadioSelectedImage] = useState(null)
  const [sureDeleteImage, setSureDeleteImage] = useState(false);
  const [deleteImagesArr, setDeleteImagesArr] = useState([])
  const [sureDeleteProduct, setSureDeleteProduct] = useState(false);

  useEffect(() => {
    getCategories()
  }, [])

  async function getCategories() {
    const response = await getCategoriesAPI()
    if (response.status === 200) {
      setCategories(response)
    }
  }

  useEffect(() => {
    setRadioSelectedImage(null);
    setSingleImage(null);
    setDeleteImagesArr([])
    setSureDeleteImage(false);
    setSureDeleteProduct(false);
    clearXInputs();
  }, [swtichTabs, isEditImagesTab])

  useEffect(() => {
    console.log(singleImage)
    console.log("DELETE IMAGES ARR", deleteImagesArr)
  }, [singleImage, radioSelectedImage, deleteImagesArr])

  function clearAllFields() {
    setCode(""); setName(""); setBrand(""); setCategoryId(""); setPrice(0); setDescription(""); setDiscount(0); setSelectedImages("");
  }

  function clearXInputs() {
    let arr = document.getElementsByClassName("x-inputs");
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].checked = false;
      }
    }
  }

  async function submitForm(e) {
    e.preventDefault()

    if (!code || !name || !brand || !price || !categoryId || !productVariants.length > 0 ) {
      toast('Enter the required fields')
      return;
    }

    let data = { productId, code, name, brand, categoryId, price, description, longDescription, discount }

    const response = await updateProductAPI(data);
    if (response.status === 200) {
      getAllProducts()
      setUpdate(false);
      // navigate(0)
    }
  }


  async function uploadMultipleImages() {
    const formData = new FormData();
    formData.append("productId", productId)

    let productImages = []
    for (let i = 0; i < selectedImages.length; i++) {
      let extArray = selectedImages[i].name.split(".");
      let extension = extArray[extArray.length - 1];
      let imageId = `29${i + 1}${Date.now()}`
      let newName = `${imageId}.${extension}`
      productImages.push({ imageId: imageId, url: newName })
      formData.append(`images`, selectedImages[i], newName)
    }
    formData.append("productImages", JSON.stringify(productImages))

    const response = await uploadMultipleImagesAPI(formData);
    if (response.status === 200) {
      getAllProducts()
      setUpdate(false);
      // navigate(0)
    }

  }

  async function updateSingleImage() {
    const imageId = radioSelectedImage.imageId;
    const oldName = radioSelectedImage.oldName;
    const ext = singleImage.name.split(".")[1]
    const newName = `${imageId}.${ext}`;


    let data = { productId, imageId, oldName, newName }
    data = JSON.stringify(data);
    const formData = new FormData()
    formData.append("data", data)
    formData.append("image", singleImage, newName);
    // for (let [key, value] of formData.entries()) { 
    //   console.log(key, value);
    // }
    const response = await updateSingleProductImageAPI(formData)
    console.log(response)
    if (response.status === 200) {
      getAllProducts()
      setUpdate(false);
      // navigate(0)
    }
  }

  // IMAGES DELETION
  function handleImageDeletetionArray(e, data) {
    const { imageId, name } = data
    const checked = e.target.checked
    console.log(imageId, name)

    setDeleteImagesArr((deleteImagesArr) => {
      deleteImagesArr = deleteImagesArr.filter(value => {
        if (value.imageId !== imageId) return value
      })
      if (checked) return [...deleteImagesArr, { imageId, name }]
      else return [...deleteImagesArr]
    })
  }

  // IMAGES DELETETION API
  async function deleteImages() {
    if (deleteImagesArr.length === 0) {
      toast("Select Images to delete");
      return;
    }
    const data = { productId, images: deleteImagesArr };
    console.log(data)
    setSureDeleteImage(false);
    const response = await deleteImagesAPI(data);
    if (response.status === 200) {
      getAllProducts()
      setUpdate(false);
      // navigate(0)
    }
  }

  async function deleteProduct() {
    console.log("Delete Item Id", productId)
    const response = await deleteProductAPI({ productId });
    if (response.status === 200) {
      getAllProducts()
      setUpdate(false);
      // navigate(0)
    }
  }

  const singleImageComponent = (
    <div className='flex mt-2 mb-3 w-full bg-white'>
      <label className="border-2 border-slate-300 rounded-l hover:bg-purple-600 hover:text-slate-200 hover:border-purple-600 py-2 px-4 transition-all duration-300 cursor-pointer" for="file_input2">Update Image</label>
      <input className="hidden" id="file_input2" type="file" accept='image/*' onChange={(e) => setSingleImage(e.target.files[0])} />
      <div className='flex-grow border-2 border-slate-300 text-slate-500 border-l-0 rounded-r flex items-center px-5 overflow-hidden'>
        <p>{!singleImage ? "Choose an Image..." : singleImage?.name}</p>
      </div>
    </div>
  )

  return (
    <div className='md:w-7/12 min-h-[70vh] flex flex-col mx-auto p-5 bg-slate-50 rounded-lg'>
      <div className='mb-3'>
        <p className='text-lg'>Update</p>
        <button onClick={e => setSwitchTabs("fields")} className={`border-b-2 p-2 ${swtichTabs === 'fields' ? 'border-purple-600 text-purple-600' : 'border-slate-400'}`}>Fields</button>
        <button onClick={e => setSwitchTabs("productVariants")} className={`border-b-2 p-2 ${swtichTabs === 'productVariants' ? 'border-purple-600 text-purple-600' : 'border-slate-400'}`}>Variants</button>
        <button onClick={e => setSwitchTabs("selectedImages")} className={`border-b-2 p-2 ${swtichTabs === 'selectedImages' ? 'border-purple-600 text-purple-600' : 'border-slate-400'}`}>Images</button>
        <button onClick={e => setSwitchTabs("moreDetails")} className={`border-b-2 p-2 ${swtichTabs === 'moreDetails' ? 'border-purple-600 text-purple-600' : 'border-slate-400'}`}>More Details</button>
        <button onClick={e => setSwitchTabs("delete")} className={`border-b-2 p-2 ${swtichTabs === 'delete' ? 'border-purple-600 text-purple-600' : 'border-slate-400'}`}>Delete</button>
      </div>

      {swtichTabs === "fields" ? (
        <div className='h-full'>
          <div className="grid grid-cols-12 gap-1 md:gap-2">
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Name <span className='text-red-600'>*</span></p>
                <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
                  onChange={(e) => { setName(e.target.value) }} value={name} />
              </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Brand <span className='text-red-600'>*</span></p>
                <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
                  onChange={(e) => { setBrand(e.target.value) }} value={brand} />
              </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Category <span className='text-red-600'>*</span></p>
                <select className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" onChange={(e) => { setCategoryId(e.target.value) }}>
                  {categories.status === 200 && typeof categories.data === 'object' && categories.data.map((category) => ((
                    <option key={category.categoryId} value={category.categoryId} selected={category.categoryId === categoryId ? true : false} className='p-2'>{category.name}</option>
                  )))}
                </select>
              </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Code <span className='text-red-600'>*</span></p>
                <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
                  onChange={(e) => { setCode(e.target.value) }} value={code} />
              </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Display Price <span className='text-red-600'>*</span></p>
                <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number"
                  onChange={(e) => { setPrice(e.target.value) }} value={price} />
              </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Display Discount</p>
                <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0} max={99}
                  onChange={(e) => { setDiscount(e.target.value) }} value={discount} />
              </div>
            </div>
            <div className='col-span-12 border-b border-slate-300'></div>
            <div className='col-span-12'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Short Description <span className='text-purple-600 text-xs'>(optional)</span></p>
                <p className="text-slate-800 mb-1 md:mb-0.5 pr-4 text-xs">Typically around 30-35 words</p>
                <textarea maxLength={250} className="resize-none h-[8vh] border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
                  onChange={(e) => { setDescription(e.target.value) }} value={description} />
              </div>
            </div>
            <div className='col-span-12'>
              <div className="mb-3">
                <p className="text-slate-800 mb-1 md:mb-0 pr-4">Long Description <span className='text-purple-600 text-xs'>(optional)</span></p>
                <p className="text-slate-800 mb-1 md:mb-0.5 pr-4 text-xs">A long description is a detailed description about a product. It could be a long paragraph.</p>
                <textarea className="resize-none min-h-[25vh] border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
                  onChange={(e) => { setLongDescription(e.target.value) }} value={longDescription} />
              </div>
            </div>
            <div className='col-span-12'>
              <div className="mt-3 flex gap-2">
                <button onClick={(e) => { submitForm(e) }} className="py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Update</button>
              </div>
            </div>
          </div>
        </div>
      )
       : swtichTabs === "productVariants" ? (
          <div>
            <ProductVariantUpdate productId={productId} prodVariants={productVariants}  />
            <p className='my-2 px-1 text-sm'>*You need to refresh the page to see changes.</p>
          </div>
       )
        // IMAGE TAB
        : swtichTabs === "selectedImages" ? (
          <div className='h-full flex-grow p-2'>
            <div className='f-one w-full border-b-2 border-slate-200 pb-3'>
              <p className="text-slate-800 mb-1 md:mb-0 pr-4">Add Images</p>
              <div className='flex mb-3 w-full bg-white'>
                <label className="border-2 border-slate-300 rounded-l hover:bg-purple-600 hover:text-slate-200 hover:border-purple-600 py-2 px-4 transition-all duration-300 cursor-pointer" for="file_input">Upload Images</label>
                <input className="hidden" id="file_input" type="file" accept='image/*' onChange={(e) => setSelectedImages(e.target.files)} multiple />
                <div className='flex-grow border-2 border-slate-300 text-slate-500 border-l-0 rounded-r flex items-center px-5 overflow-hidden'>
                  <p>{!selectedImages ? "Choose Images..." : `${selectedImages.length} files added`}</p>
                </div>
              </div>
            </div>
            {!selectedImages && (
              <div className='f-second w-full mt-5'>
                <div className='flex flex-wrap gap-2'>
                  <button onClick={() => setIsEditImagesTab(true)} className='my-3 py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Edit Images</button>
                  <button onClick={() => setIsEditImagesTab(false)} className='my-3 py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Delete Images</button>
                </div>
                <div className='py-2'>
                  <div className='flex flex-wrap gap-2'>
                    {item.images.map((image, index) => ((
                      <div className='flex flex-col items-center'>
                        <div className='image w-[90px] h-[90px] overflow-hidden' >
                          <img src={image.url} className='w-full h-full object-cover object-center' />
                        </div>
                        {isEditImagesTab ? (
                          <input className='x-inputs mt-1.5' name='select-image' type='radio' onChange={e => setRadioSelectedImage({ index: index + 1, imageId: image.imageId, oldName: image.name })} value={image.imageId} defaultChecked={radioSelectedImage?.id === image.imageId ? true : false} />
                        ) : (
                          <input className='x-inputs mt-1.5' type='checkbox' onChange={e => handleImageDeletetionArray(e, { imageId: image.imageId, name: image.name })} defaultChecked={false} />
                        )}
                      </div>
                    )))}
                  </div>
                  <div>
                    {isEditImagesTab ? (
                      <div>
                        <p className='my-2 text-sm'>Select an image to edit</p>
                        {radioSelectedImage && (
                          <div>
                            <p className='text-sm my-2'>Selected no. {radioSelectedImage.index}</p>
                            {singleImageComponent}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <p className='my-2 text-sm'>Select selectedImages to delete</p>
                        {deleteImagesArr.length > 0 && (
                          <div>
                            <p className='my-2'>Selected Images: {deleteImagesArr.length}</p>
                            <button onClick={() => { setSureDeleteImage(true) }} className='my-2 grow-0 py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Delete Selected</button>
                          </div>
                        )}
                        {deleteImagesArr.length > 0 && sureDeleteImage && (
                          <div className='flex gap-3'>
                            <p>Are you sure you want to delete?</p>
                            <button onClick={() => { deleteImages() }} className='text-rose-500 underline'>Confirm</button>
                            <button onClick={() => { setSureDeleteImage(false) }} className='underline'>Cancel</button>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
            )}
            {isEditImagesTab && (selectedImages || singleImage) && (
              <div className='h-full w-full flex gap-2 items-start'>
                <button onClick={() => { selectedImages ? uploadMultipleImages() : updateSingleImage(item.images, item.productId) }} className='mt-2 py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>{selectedImages ? 'Upload' : 'Update'}</button>
                {selectedImages && (
                  <button onClick={() => { setSelectedImages(null) }} className='mt-2 py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Cancel</button>
                )}
              </div>
            )}
          </div>
        )
          : swtichTabs === "moreDetails" ? (
            <div className='mb-5'>
              <MoreDetailsSection productId={productId} extraDetails={extraDetails} setExtraDetails={setExtraDetails} />
              <p className='my-2 px-1 text-sm'>*You need to refresh the page to see changes.</p>
            </div>
          )
            // DELETE TAB
            : (
              <div className='f-third w-full h-full my-2'>
                <div className='mb-1'>
                  <div className='w-[20vh] h-[20vh] overflow-hidden rounded-lg'>
                    <img src={item.images[0].url} className='w-full h-full object-cover object-center' />
                  </div>
                  <div className='p-3'>
                    <p>{item.code}</p>
                    <p>{item.name}</p>
                    <p>{item.brand}</p>
                  </div>
                </div>
                <div>
                  <button onClick={() => { setSureDeleteProduct(true) }} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Delete Product</button>
                </div>
                {sureDeleteProduct && (
                  <div className='mt-2 flex-col gap-3'>
                    <p>Are you sure you want to delete this product?</p>
                    <div className='flex gap-3'>
                      <button onClick={() => { deleteProduct() }} className='text-rose-500 underline'>Confirm</button>
                      <button onClick={() => { setSureDeleteProduct(false) }} className='underline'>Cancel</button>
                    </div>
                  </div>
                )}
              </div>
            )}
      <div className='flex justify-end mt-auto'>
        <button onClick={e => setUpdate(false)} className="py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Cancel</button>
      </div>
    </div>
  )
}
