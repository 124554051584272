import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useCart } from '../../lib/Providers/CartProvider';
import { toast } from 'react-toastify';
import MainTemplate from '../MainTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CartReminder from '../../components/user/CartReminder';
import { getProductByIdAPI } from '../../lib/api/productsAPI';
import Container from '../Container';
import { useAuth } from '../../lib/Providers/AuthProvider';

export default function Product() {
    // const location = useLocation();
    // const item = location.state
    const { user } = useAuth()
    let { productId } = useParams();
    const { addItemToCart } = useCart()
    const [product, setProduct] = useState(null)
    const [status, setStatus] = useState(0)
    const [variantSelector, setVariantSelector] = useState(null)

    const [quantity, setQuantity] = useState(1)
    const [bigImage, setBigImage] = useState("")

    const [isMoreImagesOpen, setIsMoreImagesOpen] = useState(false);
    const [showViewMoreButton, setShowViewMoreButton] = useState(false);
    const refImages = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0)
        getProductById()
    }, [])

    useEffect(() => {
        if (refImages.current) {
            setShowViewMoreButton(refImages.current.scrollHeight !== refImages.current.clientHeight)
        }
    }, [])

    useEffect(() => {
        document.addEventListener("click", e => {
            if (e.target.classList.contains("core-component-whole")) {
                setBigImage("");
            }
        });
    }, [])

    useEffect(() => {
        setQuantity(1)
    }, [variantSelector])

    

    async function getProductById() {
        if (productId) {
            const response = await getProductByIdAPI(productId)
            setProduct(response.data)
            setStatus(response.status)
            console.log("RES", response)
            if(response.data.variants[0].variantName === "No Variant"){
                setVariantSelector(response.data.variants[0])
            }
        }
    }

    function handleQuantity(e, op) {
        e.preventDefault()
        if (op === "plus") {
            setQuantity(quantity => {
                if (quantity < variantSelector.stock) return quantity + 1;
                else return quantity;
            })
        }
        else if (op === "minus") {
            setQuantity(quantity => {
                if (quantity > 1) return quantity - 1;
                else return quantity;
            })
        }
    }

    function handleCart(e) {
        e.preventDefault()
        if (quantity < 1) {
            return;
        }
        const newItem = { quantity: quantity, price: variantSelector.price, variantName: variantSelector.variantName, variantId: variantSelector.variantId, productId: product.productId, name: product.name, image: product.images[0].url, stock: variantSelector.stock }
        console.log("ADDING NEW ITEM", newItem)
        addItemToCart(newItem).then(() => {
            toast("Product added to the cart")
        })

    }



    return (
        <MainTemplate>
            <Container>
                {status === 0 ? (
                    <div className='flex justify-center p-2'></div>
                ) :
                    productId && status === 200 && product ? (
                        <div>
                            <CartReminder />
                            {bigImage && bigImage.length > 0 && (
                                <div className='core-component-whole fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-slate-900/20 px-2 md:px-0'>
                                    <div className='relative'>
                                        <div className='h-[70vh] bg-white overflow-hidden rounded-lg border border-slate-300 shadow shadow-slate-200'>
                                            <img className='h-full w-full object-contain object-center' src={bigImage} />
                                        </div>
                                        <button onClick={(e) => { setBigImage("") }} className='absolute top-3 right-3 text-slate-800'><FontAwesomeIcon icon={faXmark} size='2xl' /></button>
                                    </div>
                                </div>
                            )}
                            <div className='my-12 grid grid-cols-12'>
                                <div className='col-span-12 md:col-span-6'>
                                    <div className='w-full flex justify-center md:justify-start'>
                                        <div className='overflow-hidden w-[45vh] h-[45vh] md:w-[50vh] md:h-[50vh] border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                            <img src={product.images[0].url} className='w-full h-full object-cover object-center' />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`${!isMoreImagesOpen ? 'h-[13vh]' : ''} flex flex-wrap gap-2 justify-start overflow-hidden`}>
                                            {product.images.map((image, index) => ((
                                                <div onClick={(e) => { setBigImage(image.url) }} key={`image-${index}`} className='mt-2 overflow-hidden w-[80px] h-[80px] md:w-[100px] md:h-[100px] border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden cursor-pointer'>
                                                    <img src={image.url} className='w-full h-full object-cover object-center' />
                                                </div>
                                            )))}
                                        </div>
                                        <button onClick={() => setIsMoreImagesOpen(!isMoreImagesOpen)} className='mt-3 px-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300'>{isMoreImagesOpen ? 'View Less' : 'View More'}</button>
                                    </div>
                                </div>
                                <div className='col-span-12 md:col-span-6'>
                                    <div className='flex flex-col justify-center  mt-[4vh] md:mt-[12vh]'>
                                        <p className='mb-2 font-bold md:text-2xl'>{product.name}</p>
                                        <p className='mb-2 font-bold md:text-xl'> {product.brand}</p>
                                        <p className='mb-2 font-semibold md:text-lg'> {product.category.name}</p>
                                        <p className='mb-2 text-slate-600 text-sm'>{product.description}</p>
                                        <p className='mb-2 text-slate-600'>Price: {variantSelector?.price ? variantSelector.price : 'Please select a variant'}</p>
                                        <p className='mb-2 text-slate-600'>In Stock: {(variantSelector?.stock === undefined || variantSelector?.stock === null) ? 'Please select a variant' : variantSelector?.stock === 0 ? <span className='text-rose-600'>Out of stock</span> : variantSelector?.stock}</p>
                                        <div className='selector my-3'>
                                            <div className='flex flex-wrap gap-2'>
                                                {product.variants.map(variant => (
                                                    <button key={variant.variantId} onClick={() => { setVariantSelector(variant) }} className={`py-3 px-5 bg-white border-2 rounded-lg ${variantSelector?.variantId === variant.variantId ? 'border-purple-600' : 'border-slate-300'}`}>{variant.variantName}</button>
                                                ))}


                                            </div>

                                        </div>
                                        {variantSelector && variantSelector.stock > 0 && (
                                            <div className='buttons-line'>
                                                <p className='mb-2'>Selected Variant: {variantSelector?.variantName}</p>
                                                <div className='mb-4 flex gap-2'>
                                                    <div className='quantity-box flex bg-white'>
                                                        <button onClick={(e) => { handleQuantity(e, "minus") }} className='border border-slate-400 px-3 disabled:bg-slate-200'>-</button>
                                                        <div className='flex items-center text-slate-600 border-t border-b border-slate-400 px-3'>
                                                            <p>{quantity}</p>
                                                        </div>
                                                        <button onClick={(e) => { handleQuantity(e, "plus") }} className='border border-slate-400 px-3'>+</button>
                                                    </div>
                                                    <button onClick={(e) => { handleCart(e) }} className='px-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300'>Add to cart</button>

                                                </div>

                                            </div>
                                        )}
                                        <div>
                                            <p className='mb-4 text-slate-600'>Estimated delivery days in 4-5 days</p>
                                        </div>
                                    </div>
                                </div>
                                {product.longDescription && (
                                    <div className='col-span-12'>
                                        <div className='my-5 font-outfit p-5 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                            <p className='text-lg font-semibold mb-1'>Description</p>
                                            <pre className='whitespace-pre-line text-slate-600 text-sm md:text-base font-[outfit]'>{product.longDescription}</pre>
                                        </div>
                                    </div>
                                )}
                                {product.moreDetails && product.moreDetails.length > 0 && (
                                    <div className='col-span-12'>
                                        <div className='p-3 my-5 w-full bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                            <p className='text-lg font-semibold mb-1'>Details</p>
                                            <div className='table-generator overflow-auto'>
                                                <table class="w-full table-fixed border-collapse border-2 border-slate-300 bg-white">
                                                    <tbody>
                                                        {product.moreDetails.map((eachDetail, index) => (((
                                                            <tr key={`extra-detail-${index}`}>
                                                                <td className='border-2 border-slate-300 p-2'>{eachDetail.key}</td>
                                                                <td className='border-2 border-slate-300 p-2'>{eachDetail.value}</td>
                                                            </tr>
                                                        ))))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : status !== 200 && (
                        <div className='flex justify-center p-2'>
                            <p className='text-sm md:text-lg'>Oops! Seems like the product you're trying to find is not found.</p>
                        </div>
                    )}

                <div className='p-3 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                    {user && user?.role === 'user' ? (
                        <div>
                            <div className='py-2 flex gap-2 items-center'>
                                <div className='w-[50px] h-[50px] rounded-full overflow-hidden'>
                                    <img src={user?.image} className='h-full w-full object-cover object-center' />
                                </div>
                                <div>
                                    <p className='text-sm'>{user.name}</p>
                                    <p className='text-xs text-slate-400'>Drop a comment</p>
                                </div>
                            </div>
                            <div>
                                <textarea className='min-h-[10vh] w-full border-2 border-slate-300 p-2 outline-none focus:border-purple-600 transition-all duration-300 resize-none' />
                            </div>
                            <div className='flex justify-end mt-2'>
                                <button className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Comment</button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>Sign in to comment</p>
                        </div>
                    )}
                    <div className='border-slate-300 mt-5'>
                        {/* <div className='comment p-3 mb-2 flex gap-2 items-start border border-slate-300'>
                            <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                <img src='https://a.storyblok.com/f/191576/1200x800/a3640fdc4c/profile_picture_maker_before.webp' className='h-full w-full object-cover object-center' />
                            </div>
                            <div className='w-full'>
                                <p className='text-xs'>Josh Welberg</p>
                                <p className='text-xs text-slate-400'>21-12-2024</p>
                                <p className='text-sm mt-1'>Can i do anything with it? Where is the thing?</p>
                                <div className='replies'>
                                    <div className='reply-comment w-full mt-2 p-3 flex gap-2 items-start border-l-2 border-slate-300'>
                                        <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                            <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' className='h-full w-full object-cover object-center' />
                                        </div>
                                        <div className=''>
                                            <p className='text-xs'>Lighthousebd</p>
                                            <p className='text-xs text-slate-400'>22-12-2024</p>
                                            <p className='text-sm mt-1'>Yes you can</p>
                                        </div>
                                    </div>
                                    <div className='reply-comment w-full mt-2 p-3 flex gap-2 items-start border-l-2 border-slate-300'>
                                        <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                            <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' className='h-full w-full object-cover object-center' />
                                        </div>
                                        <div className=''>
                                            <p className='text-xs'>Lighthousebd</p>
                                            <p className='text-xs text-slate-400'>22-12-2024</p>
                                            <p className='text-sm mt-1'>There will be no problem using it.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        {product?.comments?.length > 0 && product.comments.map((comment => (
                            <div key={comment.commentId} className='comment p-3 mb-2 flex gap-2 items-start border border-slate-300'>
                                <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                    <img src={comment.user.image} className='h-full w-full object-cover object-center' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-xs'>{comment.user.fname} {comment.user.lname}</p>
                                    <p className='text-xs text-slate-400'>{comment.createdAt}</p>
                                    <p className='text-sm mt-1'>{comment.body}</p>
                                    <div className='replies'>
                                        {comment?.replies?.length > 0 && comment.replies.map(reply => (
                                            <div key={reply.replyId} className='reply-comment w-full mt-2 p-3 flex gap-2 items-start border-l-2 border-slate-300'>
                                                <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                                    <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' className='h-full w-full object-cover object-center' />
                                                </div>
                                                <div className=''>
                                                    <p className='text-xs'>{reply.name}</p>
                                                    <p className='text-xs text-slate-400'>{reply.createdAt}</p>
                                                    <p className='text-sm mt-1'>{reply.body}</p>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>

                </div>

            </Container>
        </MainTemplate>
    )
}
