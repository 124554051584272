import React, { useEffect, useState } from 'react'
import MainTemplate from '../MainTemplate';
import { useParams } from 'react-router-dom';
import { getProductsByCategoryAPI } from '../../lib/api/productsAPI';
import ProductsList from '../../components/user/ProductsList';
import CartReminder from '../../components/user/CartReminder';
import Container from '../Container';

export default function ProductsByCategory() {

    const { categoryName } = useParams();
    const [products, setProducts] = useState({ status: 0, data: [] })


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getProductsByCategory()
    }, [categoryName])

    async function getProductsByCategory() {
        const response = await getProductsByCategoryAPI(categoryName)
        setProducts(response)
        console.log("RES PROD BY CAT", response)
    }

    return (
        <MainTemplate>
            <Container>
                <CartReminder />

                <div className='my-5 md:my-2'>
                    <p className='text-lg'>Results for {categoryName}</p>
                </div>
            </Container>
            <div>
                <ProductsList products={products} />
            </div>
        </MainTemplate>
    )
}
