import React, { useEffect, useState } from 'react'
import MainTemplate from '../MainTemplate'
import { useCart } from '../../lib/Providers/CartProvider'
import { useAuth } from '../../lib/Providers/AuthProvider'
import { toast } from 'react-toastify'
import { getUserProfileDetailsAPI } from '../../lib/api/usersAPI'
import { useModal } from '../../lib/Providers/ModalProvider'
import { Link, useNavigate } from 'react-router-dom'
import { postOrderAPI } from '../../lib/api/ordersAPI'
import Container from '../Container'

export default function Checkout() {

    const navigate = useNavigate()
    const { user } = useAuth()
    const { cart, cartTotal, clearCartItems } = useCart()
    const { showModalConfirm } = useModal()
    const [isCheckOut, setIsCheckOut] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState()
    const [order, setOrder] = useState({ status: 0 })
    const deliveryFee = 60
    const platFormFee = 30
    const promoCode = { promo: "SP15", discountFee: 15 }
    const [paymentMethod, setPaymentMethod] = useState("Cash")
    const [deliveryAddress, setDeliveryAddress] = useState("")
    const [discountFee, setDiscountFee] = useState(0)
    const [totalInclusivePrice, setTotalInclusivePrice] = useState(0)
    const [promoCodeInput, setPromoCodeInput] = useState("")


    useEffect(() => {
        if (!user && user?.role !== 'user') navigate('/auth')
        window.scrollTo(0, 0)
        user && getUserProfileDetails()
    }, [])

    useEffect(() => {
        console.log("CART TOTAL", cartTotal)
        setTotalInclusivePrice(parseFloat(cartTotal) + deliveryFee + platFormFee)
    }, [cartTotal])

    async function getUserProfileDetails() {
        const response = await getUserProfileDetailsAPI(user?.userId)
        console.log("RES", response)
        if (response.status === 200) {
            setUserInfo(response.data)
            setDeliveryAddress(response.data.address)
        }
        else {
            toast.error(response.data)
        }
    }


    async function confirmButtonOnClick() {
        const response = await showModalConfirm({ title: "Confirm Order", body: "Are you sure you want to place your order?" })
        console.log(response)
        if (response === "confirm") {
            console.log("PROCEED ORDER HERE...")
            await placeOrder() // ORDER PLACEMENT
            setIsLoading(true);
            const timeout = setTimeout(() => {
                setIsLoading(false);
                setIsCheckOut(false);
                clearCartItems()
            }, 3000)
        }
    }

    async function placeOrder() {
        if (!user) return;
        const products = cart.map(product => { return { productId: product.productId, quantity: product.quantity, itemPrice: product.price, variantId: product.variantId, variantName: product.variantName } })
        const emailProducts = cart.map(product => { return { name: product.name, quantity: product.quantity, image: product.image, variantName: product.variantName } })
        // console.log("userid", user.userId);
        // console.log("products", products)
        // console.log("total price", cartTotal)
        // console.log("total price inclusive", totalInclusivePrice)
        // console.log("EMAIL PROUD", emailProducts)
        const totalPrice = totalInclusivePrice - discountFee
        const order = { userId: user.userId, totalPrice: totalPrice, deliveryFee: deliveryFee, additionalFee: platFormFee, status: "pending", paymentMethod: paymentMethod, deliveryAddress: deliveryAddress, discountFee: discountFee }
        const data = { order: order, products: products, user: user, emailProducts: emailProducts }
        console.log("DATA TO SEND", data)
        const response = await postOrderAPI(data);
        if (response.status === 200) {
            console.log(response);
            setOrder(response)
        }
        else {
            toast("There has been a problem, the order was not placed")
        }
    }

    function promoCodeInputChange(e) {
        setPromoCodeInput(e.target.value)
        if (e.target.value === "") {
            setDiscountFee(0)
        }
    }

    function promoCodeButtonOnClick() {
        if (promoCode.promo.toLowerCase() === promoCodeInput.toLowerCase()) {
            setDiscountFee(promoCode.discountFee)
        }
    }

    return (
        <MainTemplate>
            <Container>
                <div className='w-full md:w-10/12 mx-auto'>
                    {isLoading && (
                        <div className='fixed z-20 top-0 left-0 right-0 bottom-0 bg-slate-800 bg-opacity-50 flex flex-col justify-center items-center'>
                            <span class="loader-ripple"></span>
                        </div>
                    )}
                    {isCheckOut ? (
                        <div className='checkout-process'>
                            <div className='text-center text-lg'>
                                <p>Checkout</p>
                            </div>
                            {cart && cart.length === 0 && (
                                <div className='text-center my-2 text-sm'>
                                    <p>You've nothing to check out, add items to cart</p>
                                </div>
                            )}

                            {cart && cart.length > 0 && (
                                <div>
                                    <div className='grid grid-cols-12 gap-2 md:gap-3 mt-3'>
                                        <div className='col-span-12 md:col-span-6'>
                                            <div className='bg-white h-[55vh] md:h-[42vh] h-full overflow-auto cart-container border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-auto'>
                                                {cart && cart.map(item => (
                                                    <div key={item.productId} className='cart-item py-2 md:px-3 mb-2 flex gap-2 md:gap-3 border-b border-slate-200'>
                                                        <div className='flex flex-col justify-center px-2 md:px-0'>
                                                            <p>{item.quantity}x</p>
                                                        </div>
                                                        <div className='w-[80px] h-[80px] overflow-hidden rounded-lg'>
                                                            <img src={item.image} className='h-full w-full object-cover object-center' />
                                                        </div>
                                                        <div className='text-slate-700 flex flex-col justify-center'>
                                                            <p className='text-sm font-semibold'>{item.name} </p>
                                                            <p className='text-sm font-semibold'>Variant: {item.variantName} </p>
                                                            <p className='text-sm font-medium'>Price: {item.price} </p>
                                                            <p className='text-sm font-medium'>{item.quantity}x = {item.price * item.quantity}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {user && (
                                            <div className='col-span-12 md:col-span-6'>
                                                <div className='bg-white h-full shadow-lg border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-auto'>
                                                    <div className='w-full text-center py-2 mb-5 border-b border-slate-200'>
                                                        <p className='text-lg md:text-2xl font-semibold text-slate-800'>Billing Information</p>
                                                    </div>
                                                    <div className='flex flex-col justify-center py-3'>
                                                        {userInfo && (
                                                            <div className='flex flex-col px-2 md:px-5'>
                                                                <div className='w-[120px] h-[120px] overflow-hidden mb-3 rounded-full'>
                                                                    <img src={user.image ? user.image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile' className='w-full h-full object-cover object-center' />
                                                                </div>
                                                                <p className='text-base md:text-xl'>{user.name}</p>
                                                                <p className='text-sm'>Email: {user.email}</p>
                                                                <p className='text-sm'>Phone Number: {userInfo.phone}</p>
                                                                <p className='text-sm'>Delivery Address: {deliveryAddress}</p>
                                                                <p className='text-sm'>City: {userInfo.city}</p>
                                                                <p className='text-sm'>Selected Payment Method: {paymentMethod}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='col-span-12 md:col-span-6'>
                                            <div className='bg-white h-full p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-auto'>
                                                <div className='method-selection mb-3'>
                                                    <div className='mb-3'>
                                                        <p className='text-lg md:text-2xl font-semibold'>Payment Option</p>
                                                    </div>
                                                    <div className='max-w-[170px]'>
                                                        <select onChange={(e) => { setPaymentMethod(e.target.value) }} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" >
                                                            <option value={'Cash'} selected={true}>Cash</option>
                                                            <option value={'Card'}>Card</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p>Add an additional note to your order</p>
                                                    <textarea className='resize-none w-full min-h-[6rem] py-1 px-2 border-2 border-slate-300 rounded-sm outline-none focus:border-purple-600' />

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-12 md:col-span-6'>
                                            <div className='bg-white payment flex flex-col items-end p-4 border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                                <p className='mb-1 text-lg'>Cart Total: ${cartTotal}</p>
                                                <p className='mb-1 text-lg'>Delivery Fee: $60</p>
                                                <p className='mb-1 text-lg'>Platform Fee: $20</p>
                                                {discountFee > 0 && <p className='mb-1 text-lg'>Discount Fee: <span className='text-rose-600'>-${discountFee}</span></p>}
                                                <div className='flex flex-col items-end'>
                                                    <p className='mb-1'>Apply Promo</p>
                                                    <div className='flex gap-2'>
                                                        <input onChange={(e) => { promoCodeInputChange(e) }} value={promoCodeInput} className='py-1 px-2 border-2 border-slate-300 rounded-sm outline-none focus:border-purple-600' />
                                                        <button onClick={() => promoCodeButtonOnClick()} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md shadow-slate-300 transition-all duration-300'>Apply</button>
                                                    </div>
                                                </div>
                                                <div className='w-full border-b border-slate-300 my-3'></div>
                                                <p className='mb-1 text-end text-lg md:text-2xl'>Sub Total</p>
                                                <p className='mb-2 text-end md:text-xl'>${totalInclusivePrice - discountFee}</p>
                                                <button onClick={() => { confirmButtonOnClick() }} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 disabled:bg-slate-400 disabled:text-slate-200' disabled={paymentMethod === "Card" ? true : false}>Confirm Order</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='grid grid-cols-12 gap-2 text-slate-600'>
                            <div className='col-span-12 md:col-span-6 py-5 px-8 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                <div className='my-1 w-[100px] h-[100px] overflow-hiden'>
                                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgL54f8y2KvkViOnugSECHrjF6P0VazvrhZg&s' className='h-full w-full object-cover object-center' />
                                </div>
                                <p className='text-base md:text-2xl text-slate-400 mb-2'>Order placed successfully!</p>
                                <p className='mb-1'>Thank you, your order was placed successfully!</p>
                                <p className='mb-1'>Your order number is: {order.status === 200 && order.data.orderId}</p>
                                <p className='mb-1'>Thank you for being with us</p>
                                <p className='mb-1'>View your order details from the order section, click on your profile picture.</p>
                                <div className='mt-3 flex justify-end'>
                                    <Link to="/">
                                        <button className='px-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300'>Home Page</button>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-span-12 md:col-span-6 h-[40vh] overflow-hidden border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                <img className='h-full w-full object-cover object-center' src='/thank_you.jpeg' />
                            </div>
                        </div>
                    )
                    }
                </div>
            </Container>
        </MainTemplate>
    )
}
