import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL

export async function getOrdersAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/get/orders?skip=${data?.skip}&take=${data?.take}`,
        data: data,
    })
    .then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function postOrderAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/orders`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function changeOrderStatusAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/orders/status`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}