import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL

export async function userRegisterAPI(formData){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/users`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getAllUsersAPI(){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/users`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getUserProfileDetailsAPI(id){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/users/${id}`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function userLoginAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/user/login`,
        data: data,       
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}