import React, { useEffect, useRef, useState } from 'react'
import { useCart } from '../../lib/Providers/CartProvider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/Providers/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faBars, faStar } from '@fortawesome/free-solid-svg-icons';
import Container from '../../pages/Container';

export default function ProductsList({ products }) {

    const { cart, addItemToCart, changeQuantity } = useCart()
    const navigate = useNavigate();
    const { searchInput, setSearchInput } = useAuth().typo

    const itemsPerPage = 30
    const [sliceCount, setSliceCount] = useState(itemsPerPage)
    const obsRef = useRef(null)

    const { navH } = useAuth().nav
    const [open, setOpen] = useState(false)
    const [filterInputs, setFilterInputs] = useState({ starting: 0, ending: 100000 })


    useEffect(() => {
        setSearchInput("")
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log("Hello intersecting", entries[0].isIntersecting)
            if (entries[0].isIntersecting && sliceCount <= products?.data?.length) {
                setSliceCount(sliceCount + itemsPerPage)
            }
        })
        if (observer && obsRef.current) {
            observer.observe(obsRef.current)
        }
        console.log(sliceCount)
        return (() => {
            if (observer) observer.disconnect()
        })
    }, [sliceCount, products])

    function navigateToProduct(item) {
        // console.log("CLICKED ITEM", item)
        navigate(`/product/${item.productId}`);
    }
    function countRatings(ratings) {
        let arr = []
        for (let i = 0; i < ratings; i++) {
            arr.push(<FontAwesomeIcon icon={faStar} key={"stars-" + i + 1} size='2xs' />)
        }
        return arr;
    }

    function elevatedCartButton(item) {
        let addButton = <button onClick={() => { navigateToProduct(item) }} className='absolute right-2 bottom-2 font-medium py-2 px-2 bg-slate-200 text-slate-500 hover:bg-slate-300 hover:text-slate-800 rounded-md transition-all duration-300 text-xs md:text-sm border border-slate-200 shadow-md shadow-slate-300'>+Add</button>

        let cartItem = cart.filter(cItem => { if (cItem.productId === item.productId) return cItem })
        cartItem = cartItem[0] || null;
        if (cart.length === 0 || !cartItem) return addButton;
        else if (cartItem?.quantity > 0) {
            return (
                <div className='cart absolute right-2 bottom-2 flex justify-end text-xs md:text-sm font-semibold'>
                    <button onClick={() => { navigate('/cart') }} className='py-2 px-2.5 flex bg-violet-300 hover:bg-violet-200 text-slate-800 rounded-md overflow-hidden shadow shadow-slate-300 transition-all duration-300 cursor-pointer'>Added to cart</button>
                </div>
            )
        }
    }


    return (
        <div>
            <div className='min-h-screen'>
                <div className='filter-indication fixed ml-2 mt-2 z-10 right-0 top-0 bg-white shadow-md shadow-slate-300 rounded-lg transition-all duration-300' style={{ marginTop: navH + 10, width: open ? 270 : 0 }}>
                    <div className='relative w-full h-full'>
                        <button onClick={() => { setOpen(!open) }} className='absolute top-0 py-2 px-4 bg-slate-50 text-slate-800 border border-slate-300 shadow-md shadow-slate-500 rounded-md transition-all duration-300' style={{ left: open ? -25 : -60 }}>
                            <FontAwesomeIcon icon={open ? faAngleRight : faAngleLeft} />
                        </button>
                        <div className='px-3 py-5 overflow-hidden' style={{ display: open ? 'block' : 'none' }}>
                            <div className='py-1 mt-8 mb-1'>
                                <p className='text-sm border-b border-slate-300'>Filter Search</p>
                            </div>
                            <div className='mt-4 flex flex-wrap gap-2'>
                                <div>
                                    <p className='text-sm'>Enter Starting Price</p>
                                    <input onChange={(e) => { setFilterInputs({ ...filterInputs, starting: e.target.value }) }} value={filterInputs.starting} type='number' min={0} defaultValue={50} className='py-2 px-2 border-2 border-slate-300 focus:border-purple-600 outline-none rounded-sm' />
                                </div>
                                <div>
                                    <p className='text-sm'>Enter Ending Price</p>
                                    <input onChange={(e) => { setFilterInputs({ ...filterInputs, ending: e.target.value }) }} value={filterInputs.ending} type='number' className='py-2 px-2 border-2 border-slate-300 focus:border-purple-600 outline-none rounded-sm' />
                                </div>
                            </div>
                            <button onClick={() => { setFilterInputs({ starting: 0, ending: 100000 }) }} className='mt-5 py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Clear</button>
                        </div>
                    </div>
                </div>
                <Container>
                    <div>
                        {products.status === 200 && typeof products.data === 'object' && products.data.length > 0 && (
                            <div className='grid grid-cols-12 gap-3'>
                                {products.data.filter(product => {
                                    if (product.name.toLowerCase().includes(searchInput.toLowerCase()) || product.category.name.toLowerCase().includes(searchInput.toLowerCase())) { return product }
                                })
                                    .filter((product) => {
                                        if (filterInputs) {
                                            if (product.price >= filterInputs.starting && product.price <= filterInputs.ending) return product
                                        }
                                        else {
                                            return product
                                        }
                                    })
                                    .slice(0, sliceCount).map((item, index) => ((
                                        <div key={"productItem-" + item.productId} className='card-main relative bg-white col-span-6 md:col-span-4 lg:col-span-2 border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                            <div onClick={() => { navigateToProduct(item) }} className='card-image bg-white overflow-hidden w-full max-h-[25vh] md:max-h-[20vh] cursor-pointer'>
                                                <img src={item?.images[0]?.url} className='w-full h-full object-cover object-center' />
                                            </div>
                                            <div className='p-2 flex justify-between'>
                                                <div className='w-full'>
                                                    {/* <p className='text-xs text-slate-400'>{item.category.name}</p> */}
                                                    <p className='text-sm text-slate-500 font-medium'>{item.name}</p>
                                                    <div className='flex gap-1 items-center mb-4'>
                                                        <p className='text-base font-semibold md:font-bold text-purple-700'>${item.price}</p>
                                                        {item.discount > 0 && (<p className='text-xs text-pink-600 line-through'>${item.price + item.price * item.discount / 100}</p>)}
                                                    </div>
                                                    {/* <div className='flex gap-0.5 py-1 text-yellow-500'>
                                                    {countRatings(item.ratings)}
                                                </div> */}
                                                </div>
                                                <div className='mt-0.5'>
                                                    {item.discount > 0 && (
                                                        <div className='flex justify-end'>
                                                            <p className='font-medium text-slate-400 text-xs'>-{item.discount}%</p>
                                                        </div>
                                                    )}
                                                    {elevatedCartButton(item)}
                                                </div>
                                            </div>
                                        </div>
                                    )))}
                                {products.data.filter(product => {
                                    if (product.name.toLowerCase().includes(searchInput.toLowerCase()) || product.category.name.toLowerCase().includes(searchInput.toLowerCase())) { return product }
                                }).length === 0 && (
                                        <div className='col-span-12 py-3'>
                                            <p className='text-sm font-base text-slate-700'>No Items found for {searchInput}</p>
                                        </div>
                                    )}
                            </div>
                        )}
                        {products.status === 200 && typeof products.data === 'object' && products.data.length === 0 && (
                            <div className='w-full my-2'>
                                <p>No Products found</p>
                            </div>
                        )}
                    </div>
                </Container>

            </div>
            <div ref={obsRef}></div>
        </div>
    )
}
