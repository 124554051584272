import React, { useEffect, useState } from 'react'
import { useCart } from '../../lib/Providers/CartProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../lib/Providers/AuthProvider'
import MainTemplate from '../MainTemplate'
import Container from '../Container'

export default function Cart() {
    const { cart, clearCartItems, changeQuantity, removeItemFromCart, cartTotal, cartTotalItems } = useCart()
    const navigate = useNavigate()
    const { user } = useAuth()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function navigateToProduct(item) {
        // console.log("CLICKED ITEM", item)
        navigate(`/product/${item.productId}`);
    }

    function proceedToCheckoutBtn(e) {
        if (user?.role !== "user") {
            navigate('/auth')
        }
        else {
            navigate('/checkout')
        }
    }

    return (
        <MainTemplate>
            <Container>
                <div className='w-full md:w-10/12 mx-auto'>
                    <div className='text-center text-lg'>
                        <p className='mb-3'>Your Cart Items</p>
                        {cart && !cart.length > 0 && (
                            <p className='text-sm'>You Have no items added</p>
                        )}
                    </div>
                    <div className='min-h-[30vh] md:min-h-[40vh] cart-container'>
                        {cart && cart.map(item => ((
                            <div key={item.productId+item.variantId} className='cart-item bg-white mb-2 flex gap-2 md:gap-3 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden'>
                                <div onClick={() => { navigateToProduct(item) }} className='cursor-pointer'>
                                    <div className='w-[120px] h-[120px] overflow-hidden rounded-lg'>
                                        <img src={item.image} className='h-full w-full object-cover object-center' />
                                    </div>
                                </div>
                                <div className='text-slate-700 flex flex-col justify-center'>
                                    <p className='text-sm font-semibold'>{item.name} </p>
                                    <p className='text-sm font-semibold'>Variant: {item.variantName} </p>
                                    <p className='text-sm font-medium'>Price: {item.price} </p>
                                    <p className='text-sm font-medium'>{item.quantity}x = {item.price * item.quantity}</p>
                                    {/* <p className='text-sm'>Quantity Added: {item.quantity} </p> */}
                                    <div className='my-1 quantity-box flex'>

                                        <button onClick={() => { changeQuantity("minus", item.productId, item.variantId) }} className='border border-slate-400 px-3 disabled:bg-slate-200'>-</button>

                                        <div className='flex items-center text-slate-600 border-t border-b border-slate-400 px-3'>
                                            <p>{item.quantity}</p>
                                        </div>
                                        <button onClick={() => { changeQuantity("plus", item.productId, item.variantId) }} className='border border-slate-400 px-3'>+</button>
                                    </div>
                                </div>
                                <div className='ml-auto pr-1 md:pr-2 flex justify-center items-center gap-2'>
                                    <button onClick={() => { removeItemFromCart(item.productId, item.variantId) }} className='p-3 bg-slate-100 text-slate-800 hover:bg-slate-200 hover:text-slate-800 rounded-md transition-all duration-300 text-xs md:text-sm shadow shadow-slate-300'>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        )))}
                    </div>
                    {cart && cart.length > 0 && (
                        <div className='mt-8'>
                            <div className='mb-3 flex flex-col items-end'>
                                <p className='font-semibold'>Total Products: {cart.length}</p>
                                <p className='font-semibold'>Total Items: {cartTotalItems}</p>
                                <p className='text-lg md:text-2xl font-semibold'>Total: {cartTotal}</p>
                            </div>
                            <div className='flex justify-between text-center'>
                                <button onClick={() => { clearCartItems() }} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Clear Items</button>
                                <button onClick={(e) => { proceedToCheckoutBtn(e) }} className='py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>Proceed to Checkout</button>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </MainTemplate>
    )
}
