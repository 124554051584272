import Container from "../../pages/Container";

export default function Footer({widthControl}) {
    return (
        <div className="footer bg-slate-800 text-slate-300">
            <Container>
            <div className="p-3 md:p-5">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 md:col-span-4 flex flex-col jusity-center items-center">
                        <div className="py-2 md:py-4">
                            <p>We are available on these pages and platforms.</p>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 flex flex-col jusity-center items-center">
                        <div className="py-2 md:py-4">
                            <p>This is a sample project built with HTML, CSS, ReactJS and with backend framework NodeJS, ExpressJS etc. The project is live on cPanel hosting. This sample project is used for practice.</p>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 flex flex-col jusity-center items-center">
                        <div className="py-2 md:py-4">
                            <p className="m-0">• Alvi Noor Hossain</p>
                            <p className="m-0">• alvinoorhossain@gmail.com</p>
                            <p className="m-0">• +880 1782264033</p>
                        </div>
                    </div>
                </div>
            </div>
            </Container>
            <div className="bg-slate-700 text-center p-3 text-sm">
                <p>© 2024 Copyright - LightHouseBD</p>
            </div>
        </div>
    );
}