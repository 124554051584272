import React, { Component, useEffect, useState } from 'react'
import { faAngleDown, faAngleUp, faArrowRightToBracket, faCartShopping, faEnvelope, faHeart, faLocationDot, faMagnifyingGlass, faPhone, faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from '../../lib/Providers/CartProvider'
import { useAuth } from '../../lib/Providers/AuthProvider'
import { getCategoriesAPI } from '../../lib/api/categoryAPI'
import Container from '../../pages/Container'

export default function Navbar({ user, userSignOut }) {
    const { cart } = useCart()
    const { searchInput, setSearchInput } = useAuth().typo
    const navigate = useNavigate()
    const [showNavLinks, setShowNavLinks] = useState({ categories: false })
    const [accountDropNav, setAccountDropNav] = useState(false)
    const [categories, setCategories] = useState({ status: 0, data: [] })

    useEffect(() => {
        getCategories()
    }, [])

    async function getCategories() {
        const response = await getCategoriesAPI();
        setCategories(response);
    }

    function logOut() {
        userSignOut("user")
        setAccountDropNav(false)
        navigate('/')
        navigate(0)
    }

    useEffect(() => {
        document.addEventListener("click", e => {
            if (e.target.classList.contains("core-component-whole")) {
                setShowNavLinks({ ...showNavLinks, categories: false })
            }
        });
    }, [])

    function listCategories() {
        if (categories.status === 200) {
            const noOfItems = 5
            const divisions = Math.ceil(categories.data.length);

            let arrPrime = []
            let arr = []
            let count = 0
            for (let i = 0; i < divisions; i++) {
                for (let j = 0; j < noOfItems; j++) {
                    if (categories.data[count]) {
                        arr.push(categories.data[count])
                        count++
                    }
                }
                if (arr.length === 0) break;
                arrPrime.push(arr)
                arr = []
            }
            return (
                <div className='grid grid-cols-12 p-3'>
                    {
                        arrPrime.map((heads) => ((
                            <div className='col-span-6 md:col-span-3 lg:col-span-3'>
                                {heads.map(each => ((
                                    <button onClick={() => { navigate(`/products-category/${each.name}`); setShowNavLinks({ ...showNavLinks, categories: false }) }} className='block text-slate-800 text-sm mb-1.5'>
                                        <span className='hover-underline-effect after:bg-purple-600 pb-0.5'>{each.name}</span>
                                        <span className='text-xs font-semibold text-purple-600'> {each?.products?.length > 0 && `- ${each?.products?.length}`}</span>
                                    </button>
                                )))}
                            </div>
                        )))
                    }
                </div>
            )

        }
    }

    return (
        <div>
            {/* <div className='top-nav bg-slate-700 p-3 text-slate-200'>
                <div className='w-full sm:w-11/12 md:w-9/12 lg:w-7/12 mx-auto flex gap-2 justify-between justify-center flex-wrap'>
                    <div className='flex gap-3 flex-wrap'>
                        <div className='flex gap-1 text-xs items-center cursor-pointer hover:text-purple-600 transition-all duration-100'>
                            <FontAwesomeIcon icon={faEnvelope} size='sm' />
                            <p>example@gmail.com</p>
                        </div>
                        <div className='flex gap-1 text-xs items-center cursor-pointer hover:text-purple-600 transition-all duration-100'>
                            <FontAwesomeIcon icon={faPhone} size='sm' />
                            <p>+880 1713401968</p>
                        </div>
                        <div className='flex gap-1 text-xs items-center cursor-pointer hover:text-purple-600 transition-all duration-100'>
                            <FontAwesomeIcon icon={faLocationDot} size='sm' />
                            <p>Nowabpur</p>
                        </div>
                    </div>
                    <div className='flex gap-3 flex-wrap'>
                        <div className='flex gap-1 text-xs items-center cursor-pointer hover:text-purple-600 transition-all duration-100'>
                            <FontAwesomeIcon icon={faUser} size='sm' />
                            <p>My Account</p>
                        </div>
                        <div className='flex gap-1 text-xs items-center cursor-pointer hover:text-purple-600 transition-all duration-100'>
                            <FontAwesomeIcon icon={faArrowRightToBracket} size='sm' />
                            <p>Sign Up</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='mid-nav bg-slate-800 p-3 text-slate-200 border-b border-slate-700'>
                <Container>
                    <div className='grid grid-cols-12 gap-2'>
                        <div className='col-span-12 md:col-span-3'>
                            <div className='hidden w-full h-full md:flex gap-2 items-center justify-center md:justify-start'>
                                <Link to='/'>
                                    <div className='flex gap-2 items-center cursor-pointer'>
                                        <div className='w-[60px] rounded-lg overflow-hidden'>
                                            <img src='/logo.jpg' />
                                        </div>
                                        <p className='text-base'>Light House</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-6'>
                            <div className='w-full h-full flex flex-col justify-center'>
                                <div className='flex'>
                                    <input onChange={(e) => { setSearchInput(e.target.value) }} value={searchInput} className='w-10/12 py-2 px-2 text-slate-800 outline-none border transition-all duration-300 rounded-l-lg' />
                                    <button className='w-2/12 bg-slate-300 text-slate-800 hover:bg-purple-600 hover:text-slate-300 transition-all duration-300 rounded-r-lg'><FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-3'>
                            <div className='flex justify-center md:justify-end items-center gap-2 md:gap-4'>
                                {user && user.role === "user" ? (
                                    <div className='relative'>
                                        <div onClick={(e) => { setAccountDropNav(!accountDropNav) }} className='h-full flex gap-2 justify-center items-center cursor-pointer'>
                                            <div className='w-[55px] h-[55px] overflow-hidden'>
                                                <img src={user?.image} className='h-full w-full object-cover object-center rounded-full' />
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={!accountDropNav ? faAngleUp : faAngleDown} />
                                            </div>
                                        </div>
                                        <div className={`absolute mt-3 w-[150px] md:w-[200px] text-sm absolute bg-slate-100 rounded-lg shadow border border-slate-300 overflow-hidden ${accountDropNav ? 'fade-in' : 'fade-out'}`} style={{ zIndex: 1 }}>
                                            <button className='block w-full py-2 px-3 border-b border-slate-300 text-slate-800 hover:bg-slate-700 hover:text-slate-200 transition-all duration-300'>{user?.name}</button>
                                            <Link to='/orders'>
                                                <button className='block w-full py-2 px-3 border-b border-slate-300 text-slate-800 hover:bg-slate-700 hover:text-slate-200 transition-all duration-300'>My Orders</button>
                                            </Link>
                                            <Link to='/checkout'>
                                                <button className='block w-full py-2 px-3 border-b border-slate-300 text-slate-800 hover:bg-slate-700 hover:text-slate-200 transition-all duration-300'>Checkout</button>
                                            </Link>
                                            <button onClick={() => { logOut() }} className='block w-full py-2 px-3 border-b border-slate-300 text-slate-800 hover:bg-slate-700 hover:text-slate-200 transition-all duration-300'>Logout</button>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to='/auth'>
                                        <div className='py-3 px-4 rounded-lg relative cursor-pointer hover:bg-slate-300 hover:text-slate-800 transition-all duration-300 bg-slate-700'>
                                            <FontAwesomeIcon icon={faRightToBracket} />
                                            <p className='text-xs'>Sign In</p>
                                        </div>
                                    </Link>
                                )}
                                <Link to='/cart'>
                                    <div className='bg-slate-700 py-3 px-3 rounded-lg relative cursor-pointer hover:bg-slate-300 hover:text-slate-800 transition-all duration-300'>
                                        {cart && cart.length > 0 && (
                                            <p className='absolute py-1 px-2 rounded-full bg-purple-600 text-slate-200 text-xs' style={{ top: -4, right: -4 }}>
                                                {cart.length}
                                            </p>
                                        )}
                                        <FontAwesomeIcon icon={faCartShopping} />
                                        <p className='text-xs'>Your Cart</p>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>

            <div className='bottom-nav relative bg-slate-100 p-3 text-slate-200 border-b border-slate-300 shadow-sm shadow-slate-300'>
                <Container>
                    <div className="flex gap-2 md:gap-5 flex-wrap">
                        <Link to='/'>
                            <button className='text-slate-800 text-sm hover-underline-effect after:bg-purple-600 pb-0.5'>Home</button>
                        </Link>
                        <button onClick={() => { setShowNavLinks({ ...showNavLinks, categories: !showNavLinks.categories }) }} className='text-slate-800 text-sm hover-underline-effect after:bg-purple-600 pb-0.5'>Categories</button>
                        <button className='text-slate-800 text-sm hover-underline-effect after:bg-purple-600 pb-0.5'>Deals</button>
                    </div>
                </Container>
            </div>
            {showNavLinks.categories && (
                <div className='core-component-whole h-full fixed left-0 right-0' style={{ zIndex: 1 }}>
                    <div className='bg-slate-50 left-0 right-0 border-b border-slate-300 shadow-sm shadow-slate-300' style={{ zIndex: 2 }}>
                        <Container>
                            <div>
                                {showNavLinks.categories && (
                                    listCategories()
                                )}
                            </div>
                        </Container>
                    </div>
                </div>
            )}

        </div>
    )
}
