import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL


export async function adminLoginAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/admin/login`,
        data: data,       
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}