import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { userRegisterAPI } from '../../lib/api/usersAPI';

export default function Register() {

    const [fname, setFName] = useState("")
    const [lname, setLName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("Dhaka")
    const [selectedImage, setSelectedImage] = useState("")
    const cities = ["Dhaka", "Chittagong", "Barisal", "Khulna", "Sylhet", "Comilla", "Bogra", "Rangpur", "Rajshahi", "Mymensingh"];

    async function submitForm(e) {
        e.preventDefault()

        if (!fname || !lname || !phone || !address || !city || !email || !password) {
            return;
        }

        const formData = new FormData();

        let user = { fname, lname, phone, address, city, email, password };
        formData.append("user", JSON.stringify(user))
        formData.append("image", selectedImage)

        
        // console.log(user);
        // console.log(selectedImage);
        let response = await userRegisterAPI(formData);
        if(response.status === 200){
            toast.success(response.data)
            clearAllFields()
        }
        else{
            toast.error(response.data)
        }

    }

    function clearAllFields(){
        setFName(""); setLName(""); setEmail(""); setPassword(""); setPhone(""); setCity("Dhaka"); setAddress(""); setSelectedImage("");
    }

    return (
        <form onSubmit={(e) => submitForm(e)} className="grid grid-cols-12 gap-1 md:gap-2 p-5 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-md transition-all duration-300 overflow-hidden">
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">First Name</p>
                    <input onChange={(e) => { setFName(e.target.value) }} value={fname} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text" />
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Last Name</p>
                    <input onChange={(e) => { setLName(e.target.value) }} value={lname} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text" />
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Phone</p>
                    <input onChange={(e) => { setPhone(e.target.value) }} value={phone} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text" />
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Address</p>
                    <input onChange={(e) => { setAddress(e.target.value) }} value={address} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text" />
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">City</p>
                    <select onChange={(e) => { setCity(e.target.value) }} value={city} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" >
                        {cities && cities.map((city, index) => ((
                            <option key={'city-'+index} value={city}>{city}</option>
                        )))}
                    </select>
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'></div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Email</p>
                    <input onChange={(e) => { setEmail(e.target.value) }} value={email} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="email" />
                </div>
            </div>
            <div className='col-span-12 md:col-span-6'>
                <div className="mb-3">
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Password</p>
                    <input onChange={(e) => { setPassword(e.target.value) }} value={password} className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="password" />
                </div>
            </div>            
            <div className='col-span-12 md:col-span-6'>
                <div className='mb-3'>
                    <p className="text-slate-800 mb-1 md:mb-0 pr-4">Image</p>
                    <div className='upload-container flex mb-3 w-full'>
                        <label className="border-2 border-slate-300 rounded-l hover:bg-purple-600 hover:text-slate-200 hover:border-purple-600 py-2 px-4 transition-all duration-300 cursor-pointer" for="file_input">Upload Image</label>
                        <input className="hidden" id="file_input" type='file' accept='selectedImage/*' onChange={(e) => setSelectedImage(e.target.files[0])} />
                        <div className='flex-grow overflow-hidden border-2 border-slate-300 text-slate-500 border-l-0 rounded-r flex items-center px-5'>
                            <p className='text-sm truncate'>{selectedImage === "" ? "Choose an Image..." : selectedImage.name.toString()}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-span-12'>
                <div className="">
                    <button className="px-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4 rounded shadow-md transition-all duration-300" type="submit">Register</button>
                </div>
            </div>

        </form>
    )
}
