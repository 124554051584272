import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL

export async function addCategoryAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/categories`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getCategoriesAPI(){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/categories`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function updateCategoryAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/categories`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteCategoryAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/categories`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}