import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addProductAPI } from '../../../../lib/api/productsAPI'
import MoreDetailsSection from '../../../../components/admin/Product/MoreDetailsSection'
import { getCategoriesAPI } from '../../../../lib/api/categoryAPI'
import { getVariantsAPI } from '../../../../lib/api/variantsAPI'
import ProductVariantAdd from '../../../../components/admin/Product/ProductVariantAdd'

export default function AddProduct() {

  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [brand, setBrand] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [price, setPrice] = useState(1)
  const [description, setDescription] = useState("")
  const [longDescription, setLongDescription] = useState("")
  const [discount, setDiscount] = useState(0)
  const [selectedImages, setSelectedImages] = useState(null)
  const [extraDetails, setExtraDetails] = useState([])
  const [categories, setCategories] = useState({ status: 0, data: [] })
  const [productVariants, setProductVariants] = useState([])


  useEffect(() => {
    getCategories()
  }, [])

  async function getCategories() {
    const response = await getCategoriesAPI()
    if (response.status === 200) {
      setCategories(response)
      setCategoryId(response?.data[0]?.categoryId)
    }
  }

  function clearAllFields() {
    setCode(""); setName(""); setBrand(""); setCategoryId(""); setPrice(0); setDescription(""); setDiscount(0); setSelectedImages("");
  }

  async function submitForm(e) {
    e.preventDefault()

    if (!code || !name || !brand || !price || !categoryId || !productVariants.length > 0 ) {
      toast('Enter the required fields')
      return;
    }
    if (selectedImages?.length === 0) {
      toast('Enter image')
      return;
    }

    const formData = new FormData();

    let data = { code, name, brand, categoryId, price, description, longDescription, discount }
    formData.append("data", JSON.stringify(data))


    let productImages = []
    for (let i = 0; i < selectedImages.length; i++) {
      let extArray = selectedImages[i].name.split(".");
      let extension = extArray[extArray.length - 1];
      let imageId = `29${i + 1}${Date.now()}`
      let newName = `${imageId}.${extension}`
      productImages.push({ imageId: imageId, url: newName })
      formData.append(`images`, selectedImages[i], newName)
    }
    formData.append("productImages", JSON.stringify(productImages))
    formData.append("productVariants", JSON.stringify(productVariants))

    let moreDetails = null
    if (extraDetails && extraDetails.length > 0) {
      moreDetails = extraDetails
      formData.append("moreDetails", JSON.stringify(moreDetails))
    }

    const response = await addProductAPI(formData);
    if (response.status === 200) {
      toast(response.data)
      clearAllFields()
    }
    else {
      toast(response.data)
    }
  }



  return (
    <div className='w-full'>
      <p className='md:text-lg text-center my-2'>Add Product</p>
      <div className="grid grid-cols-12 gap-1 md:gap-2 w-full p-5 bg-slate-50 border border-slate-300 shadow-md shadow-slate-300 rounded-lg">
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Name <span className='text-red-600'>*</span></p>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setName(e.target.value) }} value={name} />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Brand <span className='text-red-600'>*</span></p>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setBrand(e.target.value) }} value={brand} />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Category <span className='text-red-600'>*</span></p>
            <select className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" onChange={(e) => { setCategoryId(e.target.value) }}>
              {categories.status === 200 && typeof categories.data === 'object' && categories.data.map((category) => ((
                <option key={category.categoryId} value={category.categoryId} selected={category.categoryId === categoryId ? true : false} className='p-2'>{category.name}</option>
              )))}
            </select>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Code <span className='text-red-600'>*</span></p>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setCode(e.target.value) }} value={code} />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Display Price <span className='text-red-600'>*</span></p>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number"
              onChange={(e) => { setPrice(e.target.value) }} value={price} />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Display Discount</p>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="number" min={0} max={99}
              onChange={(e) => { setDiscount(e.target.value) }} value={discount} />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <p className="text-slate-800 mb-1 md:mb-0 pr-4">Product Images <span className='text-red-600'>*</span></p>
          <div className='flex mb-3 w-full bg-white'>
            <label className="border-2 border-slate-300 rounded-l hover:bg-purple-600 hover:text-slate-200 hover:border-purple-600 py-2 px-4 transition-all duration-300 cursor-pointer" for="file_input">Upload Images</label>
            <input className="hidden" id="file_input" type="file" accept='image/*' onChange={(e) => setSelectedImages(e.target.files)} multiple />
            <div className='flex-grow border-2 border-slate-300 text-slate-500 border-l-0 rounded-r flex items-center px-5'>
              <p>{!selectedImages ? "Choose Images..." : `${selectedImages.length} files added`}</p>
            </div>
          </div>
        </div>
        {/* PRODUCT VARIANTS */}
        <div className='col-span-12 mb-4 border-b border-slate-300'></div>
        <div className='col-span-12'>
            <p>This section is important to fill up.</p>
            <p>Add atleast one product variant, this could mean it's size - S, M, L, XL</p>
            <p>If there are no variants then select - No Variant</p>
        </div>
        <div className='col-span-12'>
              <div>
              <ProductVariantAdd productVariants={productVariants} setProductVariants={setProductVariants}/>
              </div>
        </div>
        <div className='col-span-12 mb-4 border-b border-slate-300'></div>
        {/* PRODUCT VARIANTS */}
        <div className='col-span-12'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Short Description <span className='text-purple-600 text-xs'>(optional)</span></p>
            <p className="text-slate-800 mb-1 md:mb-0.5 pr-4 text-xs">Typically around 30-35 words</p>
            <textarea maxLength={250} className="resize-none h-[8vh] border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setDescription(e.target.value) }} value={description} />
          </div>
        </div>
        <div className='col-span-12'>
          <div className="mb-3">
            <p className="text-slate-800 mb-1 md:mb-0 pr-4">Long Description <span className='text-purple-600 text-xs'>(optional)</span></p>
            <p className="text-slate-800 mb-1 md:mb-0.5 pr-4 text-xs">A long description is a detailed description about a product. It could be a long paragraph.</p>
            <textarea className="resize-none min-h-[25vh] border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setLongDescription(e.target.value) }} value={longDescription} />
          </div>
        </div>

        <div className='col-span-12 mb-4'>
          <MoreDetailsSection extraDetails={extraDetails} setExtraDetails={setExtraDetails} />
        </div>

        <div className='col-span-12'>
          <div className="flex justify-end">
            <button onClick={(e) => { submitForm(e) }} className="py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300 text-base">Add Product</button>
          </div>
        </div>

      </div>

    </div>
  )
}
