import React, { useEffect, useState } from 'react'
import { getProductsAPI } from '../../../../lib/api/productsAPI';
import { useAuth } from '../../../../lib/Providers/AuthProvider';
import { getOrdersAPI } from '../../../../lib/api/ordersAPI';
import { getAllUsersAPI } from '../../../../lib/api/usersAPI';
import CategoryComponent from '../../../../components/admin/Home/CategoryComponent';
import SalesGraph from '../../../../analytics/SalesGraph';
import VariantComponent from '../../../../components/admin/Home/VariantComponent';


export default function AdminHome({ view }) {

  const { user } = useAuth()
  const [products, setProducts] = useState({ status: 0 });
  const [users, setUsers] = useState({ status: 0, });
  const [orders, setOrders] = useState({ status: 0, data: [] });

  useEffect(() => {
    getAllProducts()
    getAllOrders()
    getAllUsers()
  }, [])


  async function getAllUsers() {
    const response = await getAllUsersAPI()
    setUsers(response)
    console.log("RES USERS:", response)
  }

  async function getAllProducts() {
    const response = await getProductsAPI();
    setProducts(response)
    console.log("RES PRODUCTS:", response)
  }

  async function getAllOrders() {
    const response = await getOrdersAPI();
    setOrders(response)
    console.log("RES ORDERS:", response)
  }

  function calcTotalSales() {
    let total = 0
    if (orders?.status === 200) {
      let filteredOrders = orders.data.filter(order => {
        if (order.status === "delivered") return order
      })
      
      filteredOrders = filteredOrders.map(order => {
        total += order.totalPrice
        return order;
      })
    }
    return total;
  }



  return (
    <div className='w-full my-3'>
      <div className='grid grid-cols-12 gap-2'>
        <div className='col-span-6 md:col-span-3'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p className='text-xl mb-1'>Total Products</p>
            <p className='text-xl mb-1'>{products.status === 200 && products.data && products.data.length}</p>
          </div>
        </div>
        <div className='col-span-6 md:col-span-3'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p className='text-xl mb-1'>Total Orders</p>
            <p className='text-xl mb-1'>{orders.status === 200 && orders.data && orders.data.length}</p>
          </div>
        </div>
        <div className='col-span-6 md:col-span-3'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p className='text-xl mb-1'>Total Users</p>
            <p className='text-xl mb-1'>{users.status === 200 && users.data ? users.data.length : users.data}</p>
          </div>
        </div>
        <div className='col-span-6 md:col-span-3'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <p className='text-xl mb-1'>Total Sales</p>
            <p className='text-xl mb-1'>{orders.status === 200 && orders.data && calcTotalSales()}</p>
          </div>
        </div>
        <div className='col-span-12'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>              
              {orders.status === 200 && orders.data && <SalesGraph orders={orders.data} />}
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <CategoryComponent />
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className='bg-slate-50 p-3 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
            <VariantComponent />
          </div>
        </div>
      </div>
    </div>
  )
}
