import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/user/Navbar'
import Footer from '../components/user/Footer'
import { useAuth } from '../lib/Providers/AuthProvider'
// AUTH

export default function MainTemplate({ children }) {
  const { user, userSignOut } = useAuth()
  const {setNavH} = useAuth().nav
  const navRef = useRef(null)
  const [navHeight, setNavHeight] = useState('60px')

  // const widthControl = 'w-full md:w-11/12 lg:w-10/12 xl:w-8/12 mx-auto';

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNavHeight(navRef?.current?.clientHeight)
    setNavH(navRef?.current?.clientHeight)
    window.addEventListener('resize', () => {
      setNavHeight(navRef?.current?.clientHeight)
      setNavH(navRef?.current?.clientHeight)
    });

    return(() => {
      window.removeEventListener('resize', () => {setNavHeight(navRef?.current?.clientHeight); setNavH(navRef?.current?.clientHeight)})
    })

  }, [navRef, navHeight])

  // relative min-h-screen bg-gradient-to-b from-[#F6F8F5] via-[#F0F3F8] via-[#F4F8F7] via-[#F0F3F8] to-[#F6F8F5] bg-fixed bg-center text-slate-800 text-sm md:text-base

  return (
    <div className='relative min-h-screen bg-slate-50 bg-fixed bg-center text-slate-800 text-sm md:text-base'>
      <div ref={navRef} className='sticky top-0 left-0 right-0 z-10'>
        <Navbar user={user} userSignOut={userSignOut} />
      </div>
      <div className='min-h-[85vh] px-2.5 md:px-1.5 pb-5'>
        <div className='pb-[10vh] md:pb-[15vh]'>
          {children}
        </div>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  )
}
