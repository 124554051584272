import React, { useEffect, useState } from 'react'
// import { addCategoryAPI, deleteCategoryAPI, getCategoriesAPI, updateCategoryAPI } from '../../../lib/api/categoryAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { addVariantAPI, deleteVariantAPI, getVariantsAPI, updateVariantAPI } from '../../../lib/api/variantsAPI'

export default function VariantComponent() {

    const [collapsed, setCollapsed] = useState(true)
    const [variantInput, setVariantInput] = useState("")
    const [variants, setVariants] = useState({ status: 0, data: [] })
    const [isUpdate, setIsUpdate] = useState({ status: false, id: null })

    useEffect(() => {
        getVariants()
    }, [])

    async function getVariants() {
        const response = await getVariantsAPI()
        console.log("VARIANT RES", response)
        if(response.status === 200){
            setVariants(response)
        }
    }

    async function addVariant() {
        if (!variantInput) return
        const fvariants = variants?.data?.filter((variant) => { if (variantInput.toLowerCase() === variant.variantName.toLowerCase()) { return variant } })
        if (fvariants[0]) return

        const response = await addVariantAPI({ variantName: variantInput })
        if (response.status === 200) {
            console.log("VARIENT RES", response)
            getVariants()
            setVariantInput("")
        }
    }

    async function deleteVariant(id) {
        const response = await deleteVariantAPI({ variantId: id })
        if (response.status === 200) {
            getVariants()
        }
    }

    async function updateVariant() {
        const response = await updateVariantAPI({ variantId: isUpdate.id, variantName: variantInput })
        if (response.status === 200) {
            getVariants()
            setIsUpdate({ status: false, id: null })
            setVariantInput("")
        }
    }

    return (
        <div className='relative'>
            <div className='absolute w-full flex justify-center' style={{ bottom: -22 }}>
                <button onClick={() => { setCollapsed(!collapsed) }} className='bg-white py-2.5 px-6 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                    <FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp} />
                </button>
            </div>
            <p className='md:text-base'>Add Product Variants</p>
            <div className='bg-white my-2 p-2 border border-slate-300 rounded-lg text-slate-600'>
                    <p>For a specific product a variant could be it's size. An example a shirt comes with (S, M, L, XL, XXL). So add these sizes here.</p>
                </div>
            <div className='flex gap-2'>
                <input onChange={(e) => setVariantInput(e.target.value)} value={variantInput} className='w-full p-2 border-2 border-slate-300 rounded-sm outline-none focus:border-purple-600' />
                <button onClick={() => !isUpdate.status ? addVariant() : updateVariant()} className='py-2 px-6 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300'>{!isUpdate.status ? 'Add' : 'Update'}</button>
            </div>
            <div className={`overflow-hidden ${collapsed ? 'h-[10vh]' : 'h-full'}`}>
                {variants?.status === 200 && variants?.data?.length > 0 && (
                    <div className='bg-white mt-2 p-2 border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                        {variants.data.map((variant, index) => ((
                            <div key={variant.variantId} className={`p-1 flex justify-between items-center ${variants.data.length - 1 !== index && 'border-b borde-slate-300'}`}>
                                <p>
                                    {variant.variantName}                                    
                                </p>
                                <div className='flex gap-2'>
                                    <button onClick={() => { setIsUpdate({ status: true, id: variant.variantId }); setVariantInput(variant.variantName) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md'> <FontAwesomeIcon icon={faPen} /> </button>
                                    <button onClick={() => { deleteVariant(variant.variantId) }} className='py-2 px-3 text-xs bg-slate-50 text-slate-800 hover:bg-slate-200 border border-slate-300 shadow-md shadow-slate-300 transition-all duration-200 rounded-md disabled:bg-slate-300 disabled:text-slate-400' disabled={variant?.products?.length > 0}> <FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                            </div>
                        )))}
                    </div>
                )}
            </div>
        </div>
    )
}
