import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie"
import axios from "axios"
const cookies = new Cookies();
const bcrypt = require('bcryptjs');
const salt = bcrypt.genSaltSync(3);

function setAxiosToken(token){
    axios.defaults.headers.common['authorization'] = token;
}


function getInitData(){
    const token = cookies.get("auth", {});
    try{
        if(token){
            setAxiosToken(token)
            const decoded = jwtDecode(token);
            return {token: token, user: decoded}
        }
        else{
            return {token: null, user: null};
        }
    }
    catch(error){
        cookies.remove("auth")
        cookies.remove("auth-store")
        return {token: null, user: null};
    }
}
const initState = getInitData()

export const authSlice = createSlice({
    name: 'auth',
    initialState: initState,
    reducers: {
        signIn: (state, actions) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            const { token, data } = actions.payload;

            // COOKIE SET
            var hashToken = bcrypt.hashSync(token, salt);
            const decoded = jwtDecode(token);
            const date = new Date(Date.now());
            date.setDate(date.getDate() + 20); // 20 Extra days
            // const UTCdate = date.toUTCString()
            setAxiosToken(token)
            // const d = new Date(decoded.exp)
            // const path = decoded.role === "user" ? "/" : "/admin";
            cookies.remove("auth", {path: "/"});
            cookies.set("auth", token, {expires: date, path: "/"})
            cookies.set("auth-store", hashToken, {expires: date, path: "/"})
            state.token = token;
            state.user = decoded;
        },
        signOut: (state, actions) => {
            // const path = actions === "user" ? "/" : "/admin";
            cookies.remove("auth", {path: "/"});
            cookies.remove("auth-store", {path: "/"});
            setAxiosToken(undefined)
            state = {token: null, user: null};
        },
    },
})

// Action creators are generated for each case reducer function
export const { signIn, signOut } = authSlice.actions


export default authSlice.reducer