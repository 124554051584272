import axios from "axios"
import authCheck from "."
const baseUrl = process.env.REACT_APP_SERVER_URL

export async function addVariantAPI(data){
    return await axios({
        method: 'post',
        url: `${baseUrl}/api/auth/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function getVariantsAPI(){
    return await axios({
        method: 'get',
        url: `${baseUrl}/api/variants`,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function updateVariantAPI(data){
    return await axios({
        method: 'put',
        url: `${baseUrl}/api/auth/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}

export async function deleteVariantAPI(data){
    return await axios({
        method: 'delete',
        url: `${baseUrl}/api/auth/variants`,
        data: data,
    }).then((response) => {
        authCheck(response)
        return response.data
    })
    .catch((error) => {
        return {status: 400, data: error}
    })
}