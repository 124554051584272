import React, { useEffect, useState } from 'react'
import { useCart } from '../../lib/Providers/CartProvider'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

export default function CartReminder() {
    const { cart } = useCart()
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {cart && cart.length > 0 && scrollPosition > 150 && (
                <div className='fixed right-3 bg-slate-200 hover:bg-violet-200 shadow-sm shadow-slate-400 rounded-lg transition-all duration-300' style={{ top: 250, zIndex: 9 }}>
                    <Link to='/cart'>
                        <div className='relative p-2.5'>
                            <FontAwesomeIcon icon={faCartShopping} size='lg' />
                            <p className='text-xs mt-0.5'>Cart Items</p>
                            <div className='absolute' style={{ top: -3, left: -3 }}>
                                <span class="relative flex h-2.5 w-2.5">
                                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-purple-600"></span>
                                </span>
                            </div>
                        </div>
                    </Link>
                </div>
            )}
        </div>
    )
}
