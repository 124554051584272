import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faHome } from '@fortawesome/free-solid-svg-icons'

export default function AdminSidebar({ sidebarWidth, sidebar, viewState, dispatchView, sidebarDropsState, dispatchSidebarDrops }) {

    return (
        <div className='sidebar bg-slate-800 fixed top-0 left-0 bottom-0 h-full admin-sidebar-bg transition-all duration-300 overflow-hidden shadow-sm shadow-slate-800' style={{ width: sidebar ? sidebarWidth : 0, zIndex: 2 }}>
            <div className='flex justify-center items-center py-1.5'>
                <div className='overflow-hidden rounded-lg w-14 h-14'>
                    <img src='https://i.pinimg.com/originals/59/54/25/595425970319e614f8905603c87aca54.png' className='w-full h-full object-cover object-center' />
                </div>
            </div>
            <div className='overflow-auto p-3'>
                <div className='text-slate-300 mb-2 text-sm border-b border-slate-300'>
                    <p>Menu</p>
                </div>
                {/* DROPNAV */}
                <div className='mb-2 overflow-hidden rounded-md font-medium bg-slate-300/30 backdrop-blur text-slate-200'>
                    <div onClick={() => {dispatchView("AdminHome")}} className={`w-full p-2 px-3 flex items-center justify-center gap-1 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 rounded-sm cursor-pointer ${viewState === "AdminHome" ? 'bg-purple-600 text-slate-200' : ''}`}>
                            <p className='my-auto'>Home</p>                        
                    </div>                    
                </div>
                {/* PRODUCTS */}
                <div className='dropnav mb-2 overflow-hidden rounded-md font-medium bg-slate-300/30 backdrop-blur text-slate-200 relative'>
                    <div onClick={() => { dispatchSidebarDrops("toggleProducts") }} className='w-full p-2 px-3 flex items-center justify-center gap-1 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 rounded-sm cursor-pointer'>
                        <p>Products</p>
                        <div className='absolute right-3.5'>
                            <FontAwesomeIcon className='m-0' icon={sidebarDropsState.products ? faAngleUp : faAngleDown} size='sm'/>
                        </div>
                    </div>
                    <div className='' style={{ display: sidebarDropsState.products ? 'block' : 'none' }}>
                        <button onClick={() => {dispatchView("ViewProduct")}} className={`w-full p-2 text-slate-200 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 cursor-pointer rounded-sm ${viewState === "ViewProduct" ? 'bg-purple-600 text-slate-200' : ''}`}>View Products</button>
                        <button onClick={() => {dispatchView("AddProduct")}} className={`w-full p-2 text-slate-200 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 cursor-pointer rounded-sm ${viewState === "AddProduct" ? 'bg-purple-600 text-slate-200' : ''}`}>Add Product</button>
                    </div>
                </div>
                {/* ORDERS */}
                <div className='dropnav mb-2 overflow-hidden rounded-md font-medium bg-slate-300/30 backdrop-blur text-slate-200'>
                <div onClick={() => { dispatchSidebarDrops("toggleOrders") }} className='w-full p-2 px-3 flex items-center justify-center gap-1 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 rounded-sm cursor-pointer'>
                        <p>Orders</p>
                        <div className='absolute right-3.5'>
                            <FontAwesomeIcon className='m-0' icon={sidebarDropsState.orders ? faAngleUp : faAngleDown} size='sm'/>
                        </div>
                    </div>
                    <div className='' style={{ display: sidebarDropsState.orders ? 'block' : 'none' }}>
                        <button onClick={() => {dispatchView("ViewOrder")}} className={`w-full p-2 flex items-center justify-center gap-1 text-slate-200 text-center hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 cursor-pointer rounded-sm ${viewState === "ViewOrder" ? 'bg-purple-600 text-slate-200' : ''}`}>View Orders</button>                        
                    </div>
                </div>
                {/* ANALYSIS */}
                <div className='dropnav mb-2 overflow-hidden rounded-md font-medium bg-slate-300/30 backdrop-blur text-slate-200'>
                <div onClick={() => { dispatchSidebarDrops("toggleAnalysis") }} className='w-full p-2 px-3 flex items-center justify-center gap-1 hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 rounded-sm cursor-pointer'>
                        <p>Analysis</p>
                        <div className='absolute right-3.5'>
                            <FontAwesomeIcon className='m-0' icon={sidebarDropsState.analysis ? faAngleUp : faAngleDown} size='sm'/>
                        </div>
                    </div>
                    <div className='' style={{ display: sidebarDropsState.analysis ? 'block' : 'none' }}>
                        <button onClick={() => {dispatchView("ProductAnalysis")}} className={`w-full p-2 flex items-center justify-center gap-1 text-slate-200 text-center hover:bg-slate-300/40 hover:text-slate-200 transition-all duration-300 cursor-pointer rounded-sm ${viewState === "ProductAnalysis" ? 'bg-purple-600 text-slate-200' : ''}`}>Product Analysis</button>                        
                    </div>
                </div>
            </div>
        </div>
    )
}
