import React, { useEffect, useMemo, useRef, useState } from 'react'
import UpdateProduct from './UpdateProduct';
import { getProductsAPI } from '../../../../lib/api/productsAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export default function ViewProduct({ view }) {
  const [products, setProducts] = useState({ status: 0 });
  const [searchInput, setSearchInput] = useState("");
  const [update, setUpdate] = useState(false)
  const [collapsedId, setCollapsedId] = useState(-1)
  const [editItem, setEditItem] = useState({})

  const itemsPerPage = 5
  const [sliceCount, setSliceCount] = useState(itemsPerPage)
  const obsRef = useRef(null)

  useEffect(() => {
    view === "ViewProduct" && getAllProducts()
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("Hello intersecting", entries[0].isIntersecting)
      if (entries[0].isIntersecting && sliceCount <= products?.data?.length) {
        setSliceCount(sliceCount + itemsPerPage)
      }
    })
    if (observer && obsRef.current) {
      observer.observe(obsRef.current)
    }
    return (() => {
      if (observer) observer.disconnect()
    })
  }, [sliceCount, products, searchInput])

  useEffect(() => {
    if (update) {
      let x = window.scrollX;
      let y = window.scrollY;
      window.onscroll = function () { window.scrollTo(x, y); };
    }
    else {
      window.onscroll = function () { };
    }
  }, [update])

  useEffect(() => {
    document.addEventListener("click", e => {
      // console.log(e.target.classList);
      if (e.target.classList.contains("core-component-whole")) {
        setUpdate(false);
      }
    });
  }, [])

  async function getAllProducts() {
    const response = await getProductsAPI();
    setProducts({ ...products, data: [] })
    setProducts(response)
    console.log("RES", response)
  }

  function editOnClick(e, item) {
    setEditItem(item);
    setUpdate(true)
  }

  return (
    <div className='w-full'>
      <div className='min-h-screen'>
        {update && (
          <div className='vh-screen core-component-whole fixed top-0 left-0 bottom-0 right-0 z-10 flex justify-center items-center bg-slate-800 bg-opacity-50'>
            <div className='core-component-whole flex-grow h-[95vh] mx-auto rounded-lg overflow-auto py-1'>
              <UpdateProduct editItem={editItem} setUpdate={setUpdate} getAllProducts={getAllProducts} />
            </div>
          </div>
        )}
        <p className='md:text-lg text-center my-2'>View Products</p>
        <div className='w-full my-1 pt-2 pb-3'>
          <p className='text-sm'>Search Product</p>
          <div className='flex gap-2'>
            <input className="border-2 border-slate-300 rounded w-full p-2 text-slate-800 outline-none focus:border-purple-500" type="text"
              onChange={(e) => { setSearchInput(e.target.value) }} value={searchInput} />
            <button className="py-2 px-4 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Search</button>
          </div>
        </div>
        <div>
          {products.status === 200 && typeof products.data === 'object' && products.data.length > 0 && products.data.filter(product => {
            if (product.name.toLowerCase().includes(searchInput.toLowerCase()) || product.category.name.toLowerCase().includes(searchInput.toLowerCase())
              || product.code.toLowerCase().includes(searchInput.toLowerCase())) { return product }
          }).slice(0, sliceCount).map((item, index) => ((
            <div key={item.productId} className='card bg-slate-50 p-3 md:p-0 flex flex-col md:flex-row gap-2 md:gap-3 mb-2.5 border border-slate-300 shadow-md shadow-slate-300 rounded-lg overflow-hidden'>
              <div className='flex items-start p-2'>
                <div onClick={e => setCollapsedId(collapsedId === -1 || collapsedId !== item.productId ? item.productId : -1)} className='w-[80px] h-[80px] md:w-[150px] md:h-[150px] overflow-hidden rounded-lg cursor-pointer'>
                  <img src={item?.images[0]?.url} className='w-full h-full object-cover object-center' />
                </div>
              </div>
              <div className='pt-2 flex flex-grow flex-col justify-evenly'>
                <div className=''>
                  <p>Code: {item.code}</p>
                  <p className='text-rose-500'>Name: {item.name}</p>
                  <p className='text-rose-500'>Brand: {item.brand}</p>
                  <p className='text-rose-500'>Price: {item.price}</p>
                  <p>Category: {item.category.name}</p>
                </div>
                {collapsedId === item.productId && (
                  <div>
                    <div className='mb-3'>
                      <p>Description: {item.description}</p>
                      <p>Discount: {item.discount}%</p>
                    </div>

                    <div className='mb-3'>
                      <p>Product Variants | Sizes</p>
                      <div className='table-generator mb-3'>
                        {item.variants !== null && item.variants.length > 0 && (
                          <div className='table-generator overflow-auto'>
                            <table class="table-fixed border-collapse border-2 border-slate-300 bg-white">
                              <thead>
                                <tr>
                                  <th className='border-2 border-slate-300 py-1 px-2'>Variant/Size</th>
                                  <th className='border-2 border-slate-300 py-1 px-2'>Price</th>
                                  <th className='border-2 border-slate-300 py-1 px-2'>Stock</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.variants.map((variant) => (((
                                  <tr key={variant.variantId}>
                                    <td className='border-2 border-slate-300 py-1 px-2'>{variant.variantName}</td>
                                    <td className='border-2 border-slate-300 py-1 px-2'>{variant.price}</td>
                                    <td className='border-2 border-slate-300 py-1 px-2'>{variant.stock}</td>
                                  </tr>
                                ))))}
                              </tbody>
                            </table>
                          </div>
                        )}

                      </div>

                    </div>
                    <div className='table-generator mb-3'>
                      <p>More Details {item.moreDetails !== null && item.moreDetails.length > 0 ? '' : <span className='text-rose-500 font-semibold'>N/A</span>}</p>
                      {item.moreDetails !== null && item.moreDetails.length > 0 && (
                        <div className='table-generator overflow-auto'>
                          <table class="table-fixed border-collapse border-2 border-slate-300 bg-white">
                            <thead>
                              <tr>
                                <th className='border-2 border-slate-300 py-1 px-2'>Key</th>
                                <th className='border-2 border-slate-300 py-1 px-2'>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.moreDetails.map((eachDetail, index) => (((
                                <tr key={`extra-detail-${index}`}>
                                  <td className='border-2 border-slate-300 py-1 px-2'>{eachDetail.key}</td>
                                  <td className='border-2 border-slate-300 py-1 px-2'>{eachDetail.value}</td>
                                </tr>
                              ))))}
                            </tbody>
                          </table>
                        </div>
                      )}

                    </div>
                    <div className='images-edit flex flex-wrap justify-between py-2'>
                      <div className='flex flex-wrap gap-2 pb-1'>
                        {item?.images?.map((image, index) => ((
                          <div key={"more-images-" + index} className='w-[70px] h-[70px] overflow-hidden rounded-lg'>
                            <img src={image?.url} className='w-full h-full object-cover object-center' />
                          </div>
                        )))}
                      </div>
                    </div>
                    {item.longDescription && (
                      <div className='p-2 my-2 mr-2 bg-white border border-slate-300 shadow-md shadow-slate-300 rounded-lg'>
                        <p className='my-1 md:text-lg'>Long Description</p>
                        <pre className='whitespace-pre-line text-slate-600 text-sm font-[outfit]'>{item.longDescription}</pre>
                      </div>
                    )}
                  </div>
                )}
                <div className='py-2 pr-3 flex flex-wrap flex-grow gap-2 items-center'>
                  <button onClick={e => editOnClick(e, item)} className="py-2 px-5 bg-slate-700 hover:bg-slate-600 text-slate-200 rounded shadow-md transition-all duration-300">Edit</button>
                  <button onClick={e => setCollapsedId(collapsedId === -1 || collapsedId !== item.productId ? item.productId : -1)} className="flex items-center gap-1.5 py-2 px-3 bg-slate-100 text-slate-800 hover:bg-slate-200 border border-slate-300 rounded shadow-sm shadow-slate-300 transition-all duration-300">
                    <p>{collapsedId === item.productId ? 'View' : 'View'}</p>
                    <FontAwesomeIcon icon={collapsedId === item.productId ? faCaretUp : faCaretDown} size='md' />
                  </button>
                </div>
              </div>
            </div>
          )))}
        </div>
      </div>
      <div ref={obsRef}> </div>
    </div>
  )
}
